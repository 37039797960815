let options;//todas las funciones estan en el dom
let actionModule;
let networkingModule;
let followUser;
let isFollowing = false;
let isHost = false;
import { MSG_CODE } from '../constants';
import { getCameraPositionAndRotation } from './viewerModuleController';
import { ACTION_CODE } from '../../../action/src/actionConstants';
import { getSceneObject } from './objectsModuleController';
import { connectToRoom, disconnect } from './networkingModuleController';
import { leaveChannel, rejoinChannel } from './communicationModuleController';
import { enablePlayerMovementAndRotation } from './navigationModuleController';

export function initActionModule(_options) {
    return new Promise((resolve, reject) => {
        options = _options;
        actionModule = options.modules.actionModule;
        networkingModule = options.modules.networkingModule;
        resolve();
    });
}

export function actionHandler(content) {
    switch (content.action) {
        case "gather":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_MOVE_TO, position: content.position, positionLook: content.positionLook });
            break;
        case "lookTo":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_LOOKTO_ORDER, positionLook: content.positionLook });
            break;
        case "pattern":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_MOVE_TO, position: content.position, positionLook: content.positionLook });
            break;
        case "follow":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_FOLLOW_USER, userId: content.followUser, index: content.index, length: content.length });
            break;
        case "unfollow":
            options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_UNFOLLOW_USER });
            break;
        case "orbit":
            if (content.position !== null && content.position !== undefined)
                options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_ORBIT_POSITION, position: content.position });
            break;
        case "freeze":
            enablePlayerMovementAndRotation({data: { enabled: false }});
            break;
        case "unFreeze":
            enablePlayerMovementAndRotation({data: { enabled: true }});
            break
        default:
            break;
    }
}

export function loadPresentation(content) {
    enablePlayerMovementAndRotation({ data: { enabled: false } }).then(() => {
        isloadPresentation = true;
        currentActions = content.actions;
        currentIndex = 0;
        processActions();
    });
}

function processActions() {
    if (currentIndex < currentActions.length) {
        actionFinish = false;
        loadActions(currentActions[currentIndex]);
        currentIndex++;
    } else {
        enablePlayerMovement(true);
    }
}

export function loadActions(content) {
    if (content) {
        switch (content.value) {
            case ACTION_CODE.LOAD_SCENE:
                break;
            case ACTION_CODE.NAVIGATE_TO_POSITION:
                if (content.position)
                    options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_MOVE_TO, position: content.position, positionLook: content.positionLook || { x: 0, y: 1, z: 0 } });
                break;
            case ACTION_CODE.NAVIGATE_TO_OBJECT:
                if (content.target) {
                    let target = getSceneObject(content.target);
                    if (target && target.data.position) {
                        options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_MOVE_TO, position: target.data.position, positionLook: target.positionLook || { x: 0, y: 1, z: 0 } });
                    }
                }
                break;
            case ACTION_CODE.ORBIT_OBJECT:
                if (content.target) {
                    options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_ORBIT_ACTIVE, uuid: content.target, duration: content.duration });
                }
                break;
            case ACTION_CODE.ENABLE_SINGLE_USER_EXPERIENCE:
                disconnect();
                leaveChannel();
                break;
            case ACTION_CODE.DISABLE_SINGLE_USER_EXPERIENCE:
                connectToRoom();
                rejoinChannel();
                break;
            case ACTION_CODE.REMOVE_FROM_COMMUNICATIONS_CHANNEL:
                leaveChannel();
                break;
            case ACTION_CODE.REMOVE_FROM_NETWORKING_ROOM:
                disconnect();
                break;
            case ACTION_CODE.SHOW_ALERT:
                break;
            case ACTION_CODE.LOOK_AT_POSITION:
                if (content.position)
                    options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_LOOKTO_ORDER, positionLook: content.position });
                break;
            case ACTION_CODE.SHOW_MODAL:
                break;
            case ACTION_CODE.ADD_METADATA:
                break;
            case ACTION_CODE.ROTATE_360:
                options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_ROTATE_360 });
                break;
            default:
        }
    }

}

export function pathfindingFinished() {
    if (isloadPresentation) {
        actionFinish = true;
        // console.log('pathfindingFinished');
        processActions();
    }
}

export function hostActionReciever(data) {
    if(!isHost) return;
    let actordatalist = hostActorsData(data);
    actordatalist.forEach(actor => {
        let actornr = networkingModule.searchActorByUserId(actor.actorNr);
        networkingModule.sendMessage(MSG_CODE.ACTION_SEND_ORDER, actor, [actornr.actorNr]);
    });
}
export function hostActorsData(data)
{   
    let actordatalist;
    switch (data.action) {
        case ACTION_CODE.GATHER:        
            actordatalist = actionModule.gather(data.users, data.position);
            break;
        case ACTION_CODE.LOOK:
            actordatalist = actionModule.lookTo(data.users, data.position);
            break;
        case ACTION_CODE.PATTERN:
            actordatalist = actionModule.pattern(data.users,'star', data.position);
            break;
        case ACTION_CODE.ORBIT:
            actordatalist = actionModule.orbit(data.users, data.position);
            break;
        case ACTION_CODE.FREEZE:
            actordatalist = actionModule.freeze(data.users);
            break;
        case ACTION_CODE.UNFREEZE:
            actordatalist = actionModule.unFreeze(data.users);
            break;
        case ACTION_CODE.FOLLOW:
            actordatalist = actionModule.follow(data.users);
            const localActor = networkingModule.getLocalActor();
            actordatalist.forEach(actor => { actor.followUser = localActor.userId;});
            break;
        case ACTION_CODE.UNFOLLOW:
            actordatalist = actionModule.unFollow(data.users);
            break;
        default:
            return actordatalist = [];
    }
    return actordatalist;
}
export function pointerReceiver(data) {
    if(data.users == null && data.users == undefined) return;
    if(data.action !== 'gather' && data.action !== 'pattern' && data.action !== 'look' && data.action !== 'orbit') return;
    let actordatalist = hostActorsData(data);
    options.workers.offscreenCanvasWorker.postMessage({ code: MSG_CODE.ACTION_POSITION_INDICATOR, data: actordatalist });
    
}

export function setHost(value) {
    isHost = value;
}

