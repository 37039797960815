import { Injectable } from '@angular/core';

import { PageVisibilityService } from './page-visibility.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private visibilityService: PageVisibilityService) {}

  public requestNotificationPermission() {
    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      Notification.requestPermission();
      // Notification.requestPermission().then(permission => {
      //    if (permission === 'granted') {
      //       this.showNotification('Thank you for allowing notifications!');
      //    }
      // });
    }
  }

  public notifyUser(title: string, message: string, logoUrl?: string) {
    if (!this.visibilityService.isPageVisible()) {
      this.showNotification(title, message, logoUrl);
    }
  }

  private showNotification(title: string, message: string, iconUrl?: string) {
    if (Notification.permission === 'granted') {
      const options = {
        body: message,
        icon: iconUrl ? iconUrl : '/assets/images/ohio-logo.png' // Use a default icon or provide a custom one
      };
      const notification = new Notification(title, options);

      notification.onclick = event => {
        event.preventDefault(); // Prevent the browser from focusing the Notification’s tab
        this.focusOrOpenTab();
      };
    } else {
      this.requestNotificationPermission();
    }
  }

  private focusOrOpenTab() {
    const url = window.location.href;
    const windows = window.open('', '_self');

    if (windows) {
      windows.focus();
    } else {
      window.open(url, '_blank');
    }
  }


}
