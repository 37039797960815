import * as THREE from 'three';
import { Pathfinding } from './src/Pathfinding';
import { Constants } from './constants.js'
import { PathfindingHelper, } from './src/PathfindingHelper';

var scene, camera;

let pathfinding;
const helper = new PathfindingHelper();

var navmesh = null;

const SPEED = 5;
const OFFSET = 0.2;
const ZONE = 'level1';
let groupID, path;

let playerPositioned = false;
let playerPosition;
let targetPosition;

let clock;
let mouse;
let mouseDown;
let raycaster;
let content;

let Color;

var currentPathFinding = 0;
var amountPathFinding = 0;
var inMovementWithPathfinding = false;

var events = {};

export var subscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var unsubscribe = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

var emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function init(_camera) {
    pathfinding = new Pathfinding(THREE);
    console.log('====================================');
    console.log("INIT PATHFINDING WITH NAVMESH");
    console.log('====================================');
    camera = _camera;

    playerPosition = new THREE.Vector3();
    targetPosition = new THREE.Vector3();

    clock = new THREE.Clock();
    mouse = new THREE.Vector2();
    mouseDown = new THREE.Vector2();
    raycaster = new THREE.Raycaster();
    content = new THREE.Group();


    Color = {
        GROUND: new THREE.Color(0x606060),
        NAVMESH: new THREE.Color(0xFFFFFF),
    };
}


export function initPath(_scene, sceneLayer) {

    scene = _scene;
    navmesh = searchNavmesh(scene);
    content.clear();
    helper.reset()
    playerPositioned = true;

    content.add(navmesh);
    const zone = Pathfinding.createZone(navmesh.geometry);
    pathfinding.setZoneData(ZONE, zone);

    groupID = pathfinding.getGroup(ZONE, playerPosition);
    //content.visible = false;
    // scene.add(content);
    //scene.add(helper);
}

export function updatePointer(posX, posY) {
    mouseDown.set(posX, posY);
}

export function findAPath(posX, posY) {

    if (content.children.length === 0) return;

    mouse.set(posX, posY);

    playerPosition.set(camera.position.x, camera.position.y - Constants.PLAYER.HEIGHT, camera.position.z);

    camera.updateMatrixWorld();

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObject(navmesh);

    if (!intersects.length) return;

    if (!playerPositioned) {

        playerPosition.copy(intersects[0].point);
        targetPosition.copy(intersects[0].point);
        helper
            .reset()
            .setPlayerPosition(playerPosition)
            .setTargetPosition(playerPosition)
        playerPositioned = true;
        return;

    }

    targetPosition.copy(intersects[0].point);

    var data = {
        visible: true,
        pos: targetPosition,
        lookAlt: intersects[0].face.normal
    }
    emit(Constants.EVENTS_NAME.POINTER_POSITION, data);

    helper
        .reset()
        .setPlayerPosition(playerPosition);

    const targetGroupID = pathfinding.getGroup(ZONE, targetPosition, true);
    const closestTargetNode = pathfinding.getClosestNode(targetPosition, ZONE, targetGroupID, true);

    helper.setTargetPosition(targetPosition);
    if (closestTargetNode) helper.setNodePosition(closestTargetNode.centroid);

    // Calculate a path to the target and store it
    path = pathfinding.findPath(playerPosition, targetPosition, ZONE, groupID);
    amountPathFinding = path.length;
    if (path && path.length) {

        helper.setPath(path);
        inMovementWithPathfinding = true;
    } else {
        path = pathfinding.findPath(playerPosition, targetPosition, ZONE, groupID);
        amountPathFinding = path.length;
        helper.setPath(path);
    }
}

export function moveTo(targetPoint) {

}

function movementWithPathFinding(dt) {
    if (!playerPositioned || !(path || []).length) return

    let targetPosition = path[0];
    const velocity = targetPosition.clone().sub(playerPosition);

    if (velocity.lengthSq() > 0.05 * 0.05) {
        velocity.normalize();
        // Move player to target
        playerPosition.add(velocity.multiplyScalar(dt * SPEED));
        emit(Constants.EVENTS_NAME.MOVE_PLAYER_TO_POSITION, playerPosition);
        helper.setPlayerPosition(playerPosition);
    } else {
        // Remove node from the path we calculated
        if (path.length > 1)
            emit(Constants.EVENTS_NAME.LOOK_AT_TARGET, path[1]);

        path.shift();
        currentPathFinding++
        if (currentPathFinding >= amountPathFinding) {
            inMovementWithPathfinding = false;
            currentPathFinding = 0;
            var data = {
                visible: false,
                pos: new THREE.Vector3(0, 0, 0),
                lookAlt: new THREE.Vector3(0, 0, 0)
            }
            emit(Constants.EVENTS_NAME.POINTER_POSITION, data);
        }
    }
}

export function animate() {
    movementWithPathFinding(clock.getDelta());
}


export function inMovement() {
    return inMovementWithPathfinding;
}

export function cancelPathfindingMovement() {
    currentPathFinding = 0;
    inMovementWithPathfinding = false;
    path = null;
    playerPosition.set(camera.position.x, camera.position.y - Constants.PLAYER.HEIGHT, camera.position.z);
    groupID = pathfinding.getGroup(ZONE, targetPosition, true);
    const closestNode = pathfinding.getClosestNode(playerPosition, ZONE, groupID, true);

    helper.setPlayerPosition(playerPosition.copy(targetPosition));
    if (closestNode) helper.setNodePosition(closestNode.centroid);

    var data = {
        visible: false,
        pos: new THREE.Vector3(0, 0, 0),
        lookAlt: new THREE.Vector3(0, 0, 0)
    }
    emit(Constants.EVENTS_NAME.POINTER_POSITION, data);
}

function searchNavmesh(scene) {

    var tempMat = new THREE.MeshBasicMaterial({
        color: Color.NAVMESH,
        opacity: 1,
        transparent: true, //,
        wireframe: true
    });

    scene.traverse((child) => {
        if (child.name == 'Navmesh_Mesh') {
            navmesh = child.clone();
            var parent = child.parent;
            parent.remove(child);
            if (navmesh != null) {
                navmesh.visible = true;
                navmesh.material = tempMat;
                navmesh.position.y = 0.01;
            }

        }
    });
    return navmesh;
}

