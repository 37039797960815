export let Constants = {
    OCTREE: {
        GRAVITY: 30,
    },
    PLAYER: {
        RADIUS: 0.35,
        HEIGHT: 1.6,
        JUMP_SPEED: 8,
        WALK_SPEED_ON_FLOOR: 25,
        WALK_SPEED: 8,
        MAX_HEIGHT_GHOST_MODE: 9,
        MIN_HEIGHT_GHOST_MODE: 10,
        ROTATE_SPEED: 0.5
    },
    PLAYER_INPUT: {
        ESCAPE: 'Escape',
        JUMP: 'Space',
        MOVE_LEFT: 'KeyA',
        MOVE_LEFT_WITH_ARROW: 'ArrowLeft',
        MOVE_RIGHT: 'KeyD',
        MOVE_RIGHT_WITH_ARROW: 'ArrowRight',
        MOVE_UP: 'KeyW',
        MOVE_UP_WITH_ARROW: "ArrowUp",
        MOVE_DOWN: 'KeyS',
        MOVE_DOWN_WITH_ARROW: "ArrowDown",
        FREE_LOOK: "KeyF",
        MOVE_TO_DOWN: "KeyX",
    },

    STATE: {
        STOP: "stop",
        MOVEMENT: "movement",
        NEW_OBJECT: "new_object",
        MODIFY_OBJECT: "modify_object",
        HOST_ACTION: "host_action",
    },
    MODE: {
        translate: "translate",
        rotate: "rotate",
        scale: "scale"
    },

    EVENTS_NAME: {
        LOOK_AT_TARGET: "lookAtTarget",
        MOVE_PLAYER_TO_POSITION: "movePlayerToPosition",
        MODIFY_OBJECT: "modifyObject",
        APPLY_OBJECT_MODIFICATION: "applyObjectModification",
        PLAYER_MOVEMENT: "playerMovement",
        PLAYER_ROTATION: "playerRotation",
        POINTER_POSITION: "pointerPosition",
        RAYCAST_POINT: "raycastPoint",
        UPDATE_SCENEOBJECT: "onUpdateSceneObject",
        CHANGE_SCENEOBJECT: "onChangeSceneObject",
        SELECT_SCENEOBJECT: "onSelectSceneObject",
        SELECT_INTERACTABLE: "onSelectInteractable",
        RAYCAST_PREVIEW_INDICATOR: "previewIndicatorRaycast",
        RAYCAST_DEFAULT_CURSOR: "defaultCursorRaycast",
        RAYCAST_CREATE_OBJECT_CURSOR: "createObjectCursorRaycast",
        RAYCAST_HOVER_OBJECT_CURSOR: "hoverObjectCursorRaycast",
        SEND_HOST_ACTION: "sendHostAction",
        ACTION_POSITION_INDICATOR: "actionPositionIndicator",
    }
}