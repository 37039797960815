import { CommonModule } from '@angular/common';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as INTERFACE from 'linkroomv3_ui';
import { firstValueFrom } from 'rxjs';

import { AlertComponent } from '@core/components/alert/alert.component';
import { LoadingModalComponent } from '@core/components/loading-modal/loading-modal.component';
import { ConfirmOptions } from '@core/interfaces/confirm-options';
import { LoadingModalOptions } from '@core/interfaces/loading-modal-options';
import { AuthService } from '@core/services/auth.service';
import { ModalService } from '@core/services/modal.service';
import { AddProfileDto, ProfileViewModel } from '@features/models/profile.model';
import { ProfileService } from '@features/services/profile.service';
import { UserPreferencesService } from '@features/services/user-preferences.service';
import { fadeInOut } from '@shared/utils/animations';
import { APP_NAME } from '@shared/utils/constants';
import { RoomCameraContainer } from '@shared/utils/enums';
import { getInitials } from '@shared/utils/functions';
import { ImageData } from '@shared/utils/types';

import { SelfieComponent } from '../selfie/selfie.component';

@Component({
  selector: 'app-user-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SelfieComponent,
    LoadingModalComponent,
    ReactiveFormsModule,
    FormsModule
  ],
  templateUrl: './user-settings.component.html',
  styleUrl: './user-settings.component.css',
  animations: [fadeInOut]
})
export class UserSettingsComponent {
  uploadProgress: number = 0;

  @Input() isInRoom: boolean = false;
  @Input() isCameraEnabled: boolean | undefined = false;

  showSelfie: boolean = false;
  selfieTitle: string = '';
  cancelSelfieText: string = '';
  isDarkModeEnabled: boolean = false;
  showLoadingModal: boolean = false;
  enableControls: boolean = true;
  loadingModalOptions: LoadingModalOptions | null = null;

  firstName: string = '';
  lastName: string = '';
  nickName: string = '';

  originalBase64Data: string = '';

  photoUrl: string = '';
  imageData: ImageData | null = null;
  @Output() close: EventEmitter<null> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private profileService: ProfileService,
    private translateService: TranslateService,
    private userPreferencesService: UserPreferencesService,
    private modalService: ModalService
  ) {
    const userPreferences = this.userPreferencesService.getPreferences();
    this.isDarkModeEnabled = userPreferences.isDarkModeEnabled;

    this.firstName = this.authService.user?.firstName || '';
    this.lastName = this.authService.user?.lastName || '';
    this.nickName = this.authService.user?.nickName || '';
    this.photoUrl = this.authService.user?.photoUrl || '';

    this.originalBase64Data = btoa(`${this.firstName}${this.lastName}${this.nickName}`);
  }

  async onClose() {
    this.modalService.close(AlertComponent);
    const [profile, name, saving, required] = await Promise.all([
      firstValueFrom<string>(this.translateService.get('common.profile')),
      firstValueFrom<string>(this.translateService.get('user-settings.first-name')),
      firstValueFrom<string>(this.translateService.get('common.saving')),
      firstValueFrom<string>(this.translateService.get('common.validations.required'))
    ]);

    if (this.firstName.trim().length == 0) {
      this.modalService.alert({
        title: profile,
        message: `${name} ${required.toLowerCase()}`,
        icon: 'warning'
      });
      return;
    }

    this.enableControls = false;
    this.modalService.alert({ title: `${saving}...`, icon: 'info' });

    if (this.imageData) {
      this.profileService.uploadPhoto(this.authService.user?._id!, this.imageData.blob).subscribe({
        next: (event: HttpEvent<any>) => this.handleUploadEvent(event),
        error: err => console.error('Upload failed', err),
        complete: () => {
          console.log('Upload complete');
          this.authService.user!.photoUrl = this.imageData?.base64Image;
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  async save() {
    const userUpdate: Partial<AddProfileDto> = {
      firstName: this.firstName.trim(),
      lastName: this.lastName.trim(),
      nickName: this.nickName
    };

    const currentBase64Data = btoa(
      `${userUpdate.firstName}${userUpdate.lastName}${userUpdate.nickName}`
    );

    if (currentBase64Data != this.originalBase64Data) {
      await firstValueFrom(this.profileService.update(this.authService.user?._id!, userUpdate));

      this.userPreferencesService.setPreferences({
        isDarkModeEnabled: this.isDarkModeEnabled
      });

      this.authService.user!.firstName = this.firstName;
      this.authService.user!.lastName = this.lastName;
      this.authService.user!.nickName = this.nickName;

      this.modalService.close(AlertComponent);

      this.modalService.alert({
        title: APP_NAME,
        message: await firstValueFrom(this.translateService.get('common.saved')),
        icon: 'success',
        duration: 2500
      });
    } else {
      this.modalService.close(AlertComponent);
    }

    this.enableControls = true;
    this.close.emit(null);
  }

  async removeUserPicture() {
    const [acceptText, cancelText, title, message, savingText, removedText] = await Promise.all([
      firstValueFrom(this.translateService.get('common.yes')),
      firstValueFrom(this.translateService.get('common.no')),
      firstValueFrom(this.translateService.get('user-settings.profile-picture-title')),
      firstValueFrom(this.translateService.get('user-settings.profile-picture-question')),
      firstValueFrom(this.translateService.get('common.saving')),
      firstValueFrom(this.translateService.get('common.removed'))
    ]);

    let confirmOptions: ConfirmOptions = {
      title,
      message,
      cancelText,
      confirmText: acceptText
    };

    this.modalService.confirm(confirmOptions)?.subscribe(async response => {
      if (!response) return;
      this.enableControls = false;
      this.modalService.alert({ title: `${savingText}...`, icon: 'info' });
      try {
        await firstValueFrom(this.profileService.deletePhoto());
        this.photoUrl = '';
        this.authService.user!.photoUrl = '';
        this.modalService.close(AlertComponent);
        this.modalService.alert({ title: removedText, icon: 'success' });
      } catch (error: any) {
        this.modalService.close(AlertComponent);
        this.modalService.alert({ title: error.message, icon: 'error' });
      } finally {
        this.enableControls = true;
      }
    });
  }

  async checkCameraEnabled() {
    if (!this.isCameraEnabled) {
      const [message, title] = await Promise.all([
        firstValueFrom(this.translateService.get('user-settings.camera-disabled')),
        firstValueFrom(this.translateService.get('user-settings.profile-picture-title'))
      ]);
      this.modalService.alert({ message, title, icon: 'warning', duration: 5000 });
    }

    this.toggleSelfie();
  }

  async toggleSelfie() {
    this.showSelfie = !this.showSelfie;
    let videoContainerId = RoomCameraContainer.ROOM;

    if (this.showSelfie) {
      const [selfieTitle, cancelSelfieText] = await Promise.all([
        firstValueFrom(this.translateService.get('user-settings.profile-picture-title')),
        firstValueFrom(this.translateService.get('common.cancel'))
      ]);

      this.selfieTitle = selfieTitle;
      this.cancelSelfieText = cancelSelfieText;

      videoContainerId = RoomCameraContainer.SELFIE;
    }

    setTimeout(() => {
      INTERFACE.switchVideoElementId(videoContainerId);
    }, 1000);
  }

  async uploadSelfie(imageData: ImageData) {
    this.showSelfie = false;
    this.imageData = imageData;
    this.photoUrl = imageData.base64Image;
  }

  toggleDarkMode(event?: Event) {
    this.isDarkModeEnabled = !this.isDarkModeEnabled;
    document.body.classList.toggle('dark-mode', this.isDarkModeEnabled);

    if (event) {
      const targetElement = event.currentTarget as HTMLElement;
      if (
        targetElement.classList.contains('action-user-setting') ||
        targetElement.classList.contains('switch-circle')
      ) {
        targetElement.classList.toggle('active', this.isDarkModeEnabled);
      }
    }
  }

  private handleUploadEvent(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        if (event.total) {
          this.uploadProgress = Math.round((event.loaded / event.total) * 100);
          console.log(`Upload progress: ${this.uploadProgress}%`);
        }
        break;
      case HttpEventType.Response:
        console.log('Upload response:', event.body);
        this.uploadProgress = 100; // Complete
        break;
      default:
        console.log('Unhandled event:', event);
    }
  }

  getInitials() {
    getInitials(this.getLinkpadName());
  }

  getLinkpadName() {
    return this.nickName.length > 0 ? this.nickName : `${this.firstName} ${this.lastName}`;
  }
}
