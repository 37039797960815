import * as THREE from 'three';
import { Octree } from 'three/addons/math/Octree.js';
import { Capsule } from 'three/addons/math/Capsule.js';
import CameraControls from 'camera-controls';
import { Constants } from './constants.js'
import { getCurrentSceneObject } from './transformController.js';
import ORBIT from './orbitModeController.js';
import Utils from './utils.js';

let octreeClock;
var camera = null;
const EPS = 1e-5;
var inputElement = null;
var playerCollider = null;
var worldOctree = null;
let playerOnFloor = false;
let playerVelocity;
let playerDirection;
// const clock = new THREE.Clock();
const keyStates = {};
var sceneCollider = null;
var collisionBoxes = [];
var controlsEnabled = true;
var collisionEnabled = true;

var camControls;

var smoothDrag = 0.3;
var smoothDragMobile = 0.3;
var speedRotate = 3000; //1500

let fixedTimeStep = 1 / 60;
let accumulator = 0;

let spawnPosition;

let isFreelook;

let accumulatedRotation = 0;
let rotateCamera = false;

var isFitToObject = false;
var linkpadZoom = null;
var ghostModeValue = false;

var originalPointerCollider = null;

function init(_camera, _inputElement, _isFreelook) {
    // console.log('NAVIGATION: init');
    octreeClock = new THREE.Clock();
    playerVelocity = new THREE.Vector3();
    playerDirection = new THREE.Vector3();
    camera = _camera;
    isFreelook = _isFreelook;
    spawnPosition = camera.position.clone();
    camera.position.set(0, 0, EPS);
    inputElement = _inputElement;
    CameraControls.install({ THREE });

    camControls = new CameraControls(camera, inputElement);

    playerCollider = new Capsule(new THREE.Vector3(spawnPosition.x, (spawnPosition.y - Constants.PLAYER.HEIGHT), spawnPosition.z), new THREE.Vector3(spawnPosition.x, spawnPosition.y, spawnPosition.z), Constants.PLAYER.RADIUS);
    camControls.moveTo(playerCollider.end.x, playerCollider.end.y - Constants.PLAYER.RADIUS, playerCollider.end.z, false);

    camControls.azimuthRotateSpeed = isMobileDevice() == true ? -smoothDragMobile : smoothDrag;
    camControls.polarRotateSpeed = isFreelook ? (isMobileDevice() == true ? -smoothDragMobile : smoothDrag) : 0;

    camControls.saveState();

}

function initPhysics(collider) {
    return new Promise(async (resolve, reject) => {
        try {
            // console.log('NAVIGATION: initPhysics');
            sceneCollider = collider;

            if (isMobileDevice() == false) {
                worldOctree = new Octree();
                worldOctree.fromGraphNode(sceneCollider);
            }
            resolve();
        } catch (error) {
            reject(error);
        }
    });

}

function startFitToObject(object3d) {
    linkpadZoom = object3d;
    // console.log(object3d);

    isFitToObject = true;

    camControls.saveState();

    lookAtTargetInmediatly(object3d.position);

    setTimeout(() => {
        const box = new THREE.Box3().setFromObject(object3d);
        const marginFactor = 1.8;
        const size = box.getSize(new THREE.Vector3()).multiplyScalar(marginFactor);
        const center = box.getCenter(new THREE.Vector3());
        box.setFromCenterAndSize(center, size);
        camControls.fitToBox(box, true);
        setTimeout(() => {
            clampRotateCamera(true);
        }, 500)
    }, 100);

}

function stopFitToObject() {
    clampRotateCamera(false);
    camControls.reset(true);
    linkpadZoom = null;
    isFitToObject = false;
}

function enableFreelook(enabled) {
    isFreelook = enabled;
    camControls.polarRotateSpeed = isFreelook ? (isMobileDevice() == true ? -smoothDragMobile : smoothDrag) : 0;
    if (isFreelook == false) {
        camControls.rotateTo(camControls.azimuthAngle, Math.PI / 2, false);
    }
}

function modifyCollisionObject(sceneObject) {
    var data = getSizeObject(sceneObject);
    var boxCollisionFound = collisionBoxes.find(collisionBox => collisionBox.uuid === sceneObject.uuid);
    if (boxCollisionFound) {
        boxCollisionFound.scale.set(data.width, data.height, data.length);
        boxCollisionFound.position.set(data.center.x, data.center.y, data.center.z);
        boxCollisionFound.rotation.x = sceneObject.rotation.x;
        boxCollisionFound.rotation.y = sceneObject.rotation.y;
        boxCollisionFound.rotation.z = sceneObject.rotation.z;
        if (isMobileDevice() == false) {
            worldOctree.subTrees = [];
            worldOctree.box = undefined;
            worldOctree.fromGraphNode(sceneCollider);
        }
    }
}

function movePlayerToPosition(targetPosition) {
    if (targetPosition == undefined) return;
    let posYStart = (targetPosition.y + Constants.PLAYER.RADIUS);
    playerCollider.start.set(targetPosition.x, posYStart, targetPosition.z);
    let posYEnd = targetPosition.y + Constants.PLAYER.HEIGHT + Constants.PLAYER.RADIUS;
    playerCollider.end.set(targetPosition.x, posYEnd, targetPosition.z);
    camControls.moveTo(playerCollider.end.x, playerCollider.end.y, playerCollider.end.z, true);
}

function movePlayerInmediatlyTo(targetPoint) {

    playerCollider.start.set(targetPoint.x, targetPoint.y, targetPoint.z);
    playerCollider.end.set(targetPoint.x, targetPoint.y + Constants.PLAYER.HEIGHT, targetPoint.z);

    camControls.moveTo(playerCollider.end.x, playerCollider.end.y - Constants.PLAYER.RADIUS, playerCollider.end.z, false);
}

function lookAtTarget(targetPosition) {
    var playerpos = new THREE.Vector3(camera.position.x, 0, camera.position.z);
    var targetDirection = new THREE.Vector3(targetPosition.x, 0, targetPosition.z);
    targetDirection.sub(playerpos);
    targetDirection.normalize();

    camera.getWorldDirection(playerDirection);


    var camerDir = playerDirection.clone();
    camerDir.y = 0;
    camerDir.normalize();

    var angleBetween = camerDir.angleTo(targetDirection);

    var crossDirection = playerDirection.clone();
    crossDirection.cross(targetDirection);

    if (crossDirection.y < 0) {
        camControls.rotate(-angleBetween, 0, true); //bien
    } else if (crossDirection.y > 0) {
        camControls.rotate(angleBetween, 0, true); //bien
    }

}

function lookAtTargetInmediatly(targetPosition) {
    var playerpos = new THREE.Vector3(camera.position.x, 0, camera.position.z);
    var targetDirection = new THREE.Vector3(targetPosition.x, 0, targetPosition.z);
    targetDirection.sub(playerpos);
    targetDirection.normalize();

    camera.getWorldDirection(playerDirection);
    var camerDir = playerDirection.clone();
    camerDir.y = 0;
    camerDir.normalize();

    var angleBetween = camerDir.angleTo(targetDirection);

    var crossDirection = playerDirection.clone();
    crossDirection.cross(targetDirection);

    if (crossDirection.y < 0) {
        camControls.rotate(-angleBetween, 0, false); //bien
    } else if (crossDirection.y > 0) {
        camControls.rotate(angleBetween, 0, false); //bien
    }
}

function enableCollision(enabled) {
    if (enabled == true) {
        if (collisionEnabled == false) {
            if (isMobileDevice() == false) {
                worldOctree.subTrees = [];
                worldOctree.box = undefined;
                worldOctree.fromGraphNode(sceneCollider);
            }

            collisionEnabled = true;
        }
    } else {
        if (collisionEnabled == true) {
            if (isMobileDevice() == false) {
                worldOctree.subTrees = [];
                worldOctree.box = undefined;
            }
            collisionEnabled = false;
        }

    }
}

function addCollisionObject(sceneObject, createBoxCollision = true) {

    if (createBoxCollision == true) {
        var data = getSizeObject(sceneObject);
        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00, wireframe: true });
        const boxCollision = new THREE.Mesh(geometry, material);
        boxCollision.scale.set(data.width, data.height, data.length);
        boxCollision.position.set(data.center.x, data.center.y, data.center.z);
        boxCollision.name = "sceneObjectCollision";
        boxCollision.uuid = sceneObject.uuid;
        sceneCollider.add(boxCollision);
        collisionBoxes.push(boxCollision);

    } else {
        var clone = sceneObject.clone();
        clone.uuid = sceneObject.uuid;
        sceneCollider.add(clone);
        collisionBoxes.push(clone);
    }

    if (isMobileDevice() == false) {
        worldOctree.clear();

        worldOctree.subTrees = [];
        worldOctree.triangles = [];
        worldOctree.box = null;
        worldOctree.layers = new THREE.Layers();
        worldOctree.bounds = new THREE.Box3();
        worldOctree.fromGraphNode(sceneCollider);
    }

}

function removeCollisionObject(sceneobject) {

    const boxCollisionObject = collisionBoxes.find((obj) => { return obj.uuid === sceneobject.uuid });

    sceneCollider.remove(boxCollisionObject);

    let index = collisionBoxes.indexOf(boxCollisionObject);

    if (index !== -1) {
        collisionBoxes.splice(index, 1);
    }
    if (isMobileDevice() == false) {
        worldOctree.subTrees = [];
        worldOctree.box = undefined;
        worldOctree.fromGraphNode(sceneCollider);
    }

}

function registerKeyCode(eventCode, state) {
    keyStates[eventCode] = state;
}

function enableCameraRotation(enable) {
    camControls.enabled = enable;
}

function playerCollisions() {

    const result = worldOctree.capsuleIntersect(playerCollider);
    playerOnFloor = false;
    if (result) {
        playerOnFloor = result.normal.y > 0;
        if (!playerOnFloor) {
            playerVelocity.addScaledVector(result.normal, - result.normal.dot(playerVelocity));
        }
        playerCollider.translate(result.normal.multiplyScalar(result.depth));
    }
}

function updatePlayer(deltaTime) {
    let damping = Math.exp(- 4 * deltaTime) - 1;
    if (!playerOnFloor) {
        if (!ghostModeValue) playerVelocity.y -= Constants.OCTREE.GRAVITY * deltaTime;
        damping *= 0.1;
    }
    playerVelocity.addScaledVector(playerVelocity, damping);
    const deltaPosition = playerVelocity.clone().multiplyScalar(deltaTime);
    playerCollider.translate(deltaPosition);
    camControls.moveTo(playerCollider.end.x, playerCollider.end.y - Constants.PLAYER.RADIUS, playerCollider.end.z, false);
    if (isMobileDevice() == false) {
        if (!ghostModeValue) playerCollisions();
    } else {
        playerOnFloor = true;
    }

    camera.position.copy(playerCollider.end);


}

function getForwardVector() {
    camera.getWorldDirection(playerDirection);
    playerDirection.y = 0;
    playerDirection.normalize();
    return playerDirection;
}

function getSideVector() {
    camera.getWorldDirection(playerDirection);
    playerDirection.y = 0;
    playerDirection.normalize();
    playerDirection.cross(camera.up);
    return playerDirection;
}

function controls(deltaTime) {

    const speedDelta = deltaTime * (playerOnFloor ? Constants.PLAYER.WALK_SPEED_ON_FLOOR : Constants.PLAYER.WALK_SPEED);
    if (keyStates[Constants.PLAYER_INPUT.MOVE_UP] || keyStates[Constants.PLAYER_INPUT.MOVE_UP_WITH_ARROW]) {
        playerVelocity.add(getForwardVector().multiplyScalar(speedDelta));
    }
    if (keyStates[Constants.PLAYER_INPUT.MOVE_DOWN] || keyStates[Constants.PLAYER_INPUT.MOVE_DOWN_WITH_ARROW]) {
        playerVelocity.add(getForwardVector().multiplyScalar(- speedDelta));
    }

    if (keyStates[Constants.PLAYER_INPUT.MOVE_RIGHT]) {
        playerVelocity.add(getSideVector().multiplyScalar(speedDelta));
    }
    if (keyStates[Constants.PLAYER_INPUT.MOVE_LEFT]) {
        playerVelocity.add(getSideVector().multiplyScalar(-speedDelta));
    }

    if (keyStates[Constants.PLAYER_INPUT.MOVE_RIGHT_WITH_ARROW]) {
        camControls.rotate(-Constants.PLAYER.ROTATE_SPEED * THREE.MathUtils.DEG2RAD, 0, true);
    }

    if (keyStates[Constants.PLAYER_INPUT.MOVE_LEFT_WITH_ARROW]) {
        camControls.rotate(Constants.PLAYER.ROTATE_SPEED * THREE.MathUtils.DEG2RAD, 0, true);
    }

    if (keyStates[Constants.PLAYER_INPUT.MOVE_RIGHT_WITH_ARROW]) {
        // console.log("awdawdwad");
        // let deltaTime = Math.min(2, clock.getDelta());
    }
    if (keyStates[Constants.PLAYER_INPUT.MOVE_LEFT_WITH_ARROW]) {
        let deltaTime = Math.min(2, octreeClock.getDelta());
    }

    if (ghostModeValue) {
        if (keyStates[Constants.PLAYER_INPUT.JUMP]) {
            //console.log(Constants.PLAYER.MAX_HEIGHT_GHOST_MODE, camera.position.y);
            if (Constants.PLAYER.MAX_HEIGHT_GHOST_MODE > camera.position.y) {
                playerVelocity.y = 2;
            }
        }
        if (keyStates[Constants.PLAYER_INPUT.MOVE_TO_DOWN]) {
            //console.log(Constants.PLAYER.MIN_HEIGHT_GHOST_MODE, camera.position.y);
            if (Constants.PLAYER.MIN_HEIGHT_GHOST_MODE < camera.position.y) {
                playerVelocity.y = -2;
            }
        }

    } else {
        if (playerOnFloor) {
            if (keyStates[Constants.PLAYER_INPUT.JUMP]) {
                playerVelocity.y = Constants.PLAYER.JUMP_SPEED;
            }
        }
    }

}

function getPlayerVelocity() {
    return playerVelocity;
}

function getPlayerDirection() {
    return playerDirection;
}

function setPlayerVelocity(_playerVelocity) {
    playerVelocity.copy(_playerVelocity);
}

function setPlayerDirection(_playerDirection) {
    playerDirection.copy(_playerDirection);
}

function enableControls(value) {
    controlsEnabled = value;
}

function animate() {
    // if (worldOctree == null) return;
    // let v = isMacDevice() ? 0.015 : 0.05;
    let v = 0.015;
    const deltaTime = Math.min(v, octreeClock.getDelta());
    // console.log(deltaTime);
    if (controlsEnabled == true) {
        controls(deltaTime);
    }
    if (isFitToObject == false) {
        updatePlayer(deltaTime);
    }

    const inOrbitMode = ORBIT.getInOrbitMode();
    if (!inOrbitMode) camControls.update(deltaTime);

    if (rotateCamera) rotateCamera360(deltaTime);
}

function rotateCamera360(deltatime) {
    const fullRotation = 2 * Math.PI;
    if (accumulatedRotation < fullRotation) {
        const rotationSpeed = (deltatime * 15) * THREE.MathUtils.DEG2RAD; // Adjust speed    
        camControls.rotate(rotationSpeed, 0, true);
        accumulatedRotation += rotationSpeed;
        if (accumulatedRotation >= fullRotation) {
            accumulatedRotation = fullRotation;
            // console.log('Rotation completed');
            rotateCamera = false;
        }
    }
}

function start360CameraRotation() {
    accumulatedRotation = 0;
    rotateCamera = true;
}

const OctreeController = {
    init,
    initPhysics,
    registerKeyCode,
    enableCameraRotation,
    enableCollision,
    enableControls,
    addCollisionObject,
    removeCollisionObject,
    modifyCollisionObject,
    getPlayerVelocity,
    setPlayerVelocity,
    getPlayerDirection,
    setPlayerDirection,
    lookAtTarget,
    movePlayerToPosition,
    movePlayerInmediatlyTo,
    lookAtTargetInmediatly,
    animate,
    enableFreelook,
    isMobileDevice,
    getSizeObject,
    start360CameraRotation,
    startFitToObject,
    stopFitToObject,
    clampRotateCamera,
    checkCollisions,
    ghostMode
}

function getSizeObject(_obj) {
    // var obj = _obj.clone();
    // obj.setRotationFromEuler(new THREE.Euler());
    var box = new THREE.Box3().setFromObject(_obj);
    const size = new THREE.Vector3();
    box.getSize(size);
    var centerPosition = new THREE.Vector3();
    box.getCenter(centerPosition);
    // var object3DWidth = box.max.x - box.min.x;
    // var object3DHeight = box.max.y - box.min.y;
    // var object3Length = box.max.z - box.min.z;
    return { width: size.x, height: size.y, length: size.z, center: centerPosition };
}

function isMobileDevice() {
    var userAgent = navigator.userAgent;
    var mobilePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (mobilePattern.test(userAgent))
        return true
    else
        return false;
}

function isMacDevice() {
    var userAgent = navigator.userAgent;
    var macPattern = /Macintosh|MacIntel|MacPPC|Mac68K/i;
    return macPattern.test(userAgent);
}

function clampRotateCamera(value) {
    if (value) {
        camControls.maxAzimuthAngle = camControls.azimuthAngle + 0.5;
        camControls.minAzimuthAngle = camControls.azimuthAngle - 0.5;
    } else {
        camControls.minAzimuthAngle = -Infinity;
        camControls.maxAzimuthAngle = Infinity;
    }
}

export function getSceneCollier() {
    return worldOctree;
}

function checkCollisions(cube) {
    if (originalPointerCollider == null) {
        //originalPointerCollider = new Capsule(new THREE.Vector3(0, 0, 0, new THREE.Vector3(0, 1, 0), 0.05));
        originalPointerCollider = new THREE.Sphere(new THREE.Vector3(0, 0, 0), 0.1);
    }
    else {
        // originalPointerCollider.start.copy(cube.position).add(new THREE.Vector3(0, 0, 0));
        // originalPointerCollider.end.copy(cube.position).add(new THREE.Vector3(0, 1, 0));
        originalPointerCollider.center.copy(cube.position).add(new THREE.Vector3(0, 0.1, 0));
    }

    if (worldOctree.sphereIntersect(originalPointerCollider) != false) {
        //console.log(worldOctree.sphereIntersect(originalPointerCollider));
        return true;
    }
    else {
        return false;
    }
}

function ghostMode(value) {
    let newSizeHelper = Utils.getSizeObject(sceneCollider);
    let vectorSidepos = new THREE.Vector3(0, 0, 0);
    vectorSidepos.copy(newSizeHelper.center);
    vectorSidepos.y += newSizeHelper.height / 2;
    Constants.PLAYER.MAX_HEIGHT_GHOST_MODE = vectorSidepos.y - (Constants.PLAYER.HEIGHT / 2);
    vectorSidepos.copy(newSizeHelper.center);
    vectorSidepos.y -= newSizeHelper.height / 2;
    Constants.PLAYER.MIN_HEIGHT_GHOST_MODE = vectorSidepos.y + (Constants.PLAYER.HEIGHT / 2);
    ghostModeValue = value;
}

export default OctreeController;
