let options = {};
export function initUtils(_options){
   options = _options;
}

export function getSpecs(){
    var canvas = document.createElement('canvas');
    var gl = canvas.getContext('webgl');

    const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
    let contextRenderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

    let response = {};

    const deviceType = getDeviceType();
    if(deviceType == 'Mobile' || deviceType == 'Tablet'){
        if(navigator.userAgent.includes('Android')){
            response = { deviceType:'Mobile', so: 'Android', maxParallelTasks: 6};
        }else{
            response = { deviceType:'Mobile', so: 'iOS', maxParallelTasks: 3};            
        }
    }else{
        const desktopType = getDesktopType();
        const info =  extractDesktopGPUName(contextRenderer);
        let maxParallelTasks = 80;
        if(info.dedicated){
            if(info.gpu=='APPLE'){
                maxParallelTasks = 10;
            }else{
                maxParallelTasks = 80;
            }
        }else{
            maxParallelTasks = 5;
        }
        response = { deviceType: 'Desktop', so: desktopType, info, maxParallelTasks};
    }

    return response;
}

function extractDesktopGPUName(renderer) {

    const knownAppleGPUs = [
        'M1 Ultra', 'M1 Max', 'M2 Ultra', 'M2 Max', 'M2 Pro', 'M2', 'M3 Ultra', 'M3 Max', 'M3 Pro', 'M3'
    ];

    const knowIntegratedGPU = [
        'Intel', 'Iris', 'M1 Pro' ,'M1',
    ];

    const knowDedicatedGPU = [
        'AMD', 'NVIDIA', 'GeForce', 'Radeon'
    ]

    for (const gpu of knownAppleGPUs) {
        if (renderer.includes(gpu)) {
            return { gpu: 'APPLE '+ gpu, dedicated: true};
        }
    }

    for (const gpu of knowIntegratedGPU) {
        if (renderer.includes(gpu)) {
            return { gpu, dedicated: false};
        }
    }

    for (const gpu of knowDedicatedGPU) {
        if (renderer.includes(gpu)) {
            return { gpu: 'NVIDIA' + extractNvidiaGPUName(renderer), dedicated: true};
        }
    }

    return renderer;
} 

function extractNvidiaGPUName(renderer) {
    const match = renderer.match(/NVIDIA\s([A-Za-z0-9\s]+)/);
    return match ? match[1].trim() : renderer;
}

function getDeviceType() {
    const ua = navigator.userAgent;
    if (/tablet|ipad|playbook|silk/i.test(ua) && !/mobile/i.test(ua)) {
        return "Tablet";
    }
    if (/Mobile|iPhone|Android|IEMobile|BlackBerry|Kindle|Silk-Accelerated|Opera Mini|Opera Mobi/i.test(ua)) {
        return "Mobile";
    }
    return "Desktop";
}

function getDesktopType(){
    const ua = navigator.userAgent;
    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(ua)) {
        return "macOS";
    }
    
    if (/Win32|Win64|Windows|WinCE/.test(ua)) {
        return "Windows";
    }   

    return 'Linux';   
}