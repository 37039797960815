import * as THREE from 'three';

function getSizeObject(_obj) {
    var obj = _obj.clone();
    obj.setRotationFromEuler(new THREE.Euler());
    var box = new THREE.Box3().setFromObject(obj);
    var centerPosition = new THREE.Vector3();
    box.getCenter(centerPosition);
    var object3DWidth = box.max.x - box.min.x;
    var object3DHeight = box.max.y - box.min.y;
    var object3Length = box.max.z - box.min.z;
    return { width: object3DWidth, height: object3DHeight, length: object3Length, center: centerPosition };
}

function isMobileDevice() {
    var userAgent = navigator.userAgent;
    var mobilePattern = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    if (mobilePattern.test(userAgent))
        return true
    else
        return false;
}



const Utils = {
    getSizeObject,
    isMobileDevice
}

export default Utils;