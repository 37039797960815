import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-modal',
  standalone: true,
  imports: [],
  templateUrl: './loading-modal.component.html',
  styleUrl: './loading-modal.component.css'
})
export class LoadingModalComponent {
   @Input() title: string = '';
   @Input() message: string | null = null;
   @Input() loading: boolean = false;
}
