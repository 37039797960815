import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageVisibilityService {
  private renderer: Renderer2;
  private visibilitySubject = new BehaviorSubject<boolean>(true);
  visibility$ = this.visibilitySubject.asObservable();

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.initVisibilityListener();
  }

  private initVisibilityListener() {
    this.renderer.listen('document', 'visibilitychange', () => {
      this.visibilitySubject.next(!document.hidden);
    });
  }

  isPageVisible(): boolean {
    return !document.hidden;
  }
}
