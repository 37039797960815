import { v4 as uuidv4 } from 'uuid';

let keys;
let token = null;
let photonLibraryPath;
let auto = false;
let client;
let region = 'US';
let appVersion = "1.2";

let localActor = null;
let otherActors = {};

export const EVENT = {
    RECEIVE_MESSAGE: 'receiveMessage',
    ACTOR_JOIN: 'actorjoin',
    ACTOR_LEAVE: 'actorLeave',
    JOINED_LOBBY: 'joinedLobby',
    ROOM_PROPERTIES_CHANGE: 'roomPropertiesChange',
    ERROR: 'error',
}

let events = {};

export var on = (_event, listener) => {
    if (!events[_event]) {

        events[_event] = [];
    }
    events[_event].push(listener);
}

export var off = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

let emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

export function init(options) {
    return new Promise(resolve => {
        keys = options.apikeys;
        token = options.apikeys.photonToken;
        // photonLibraryPath = options.photonSDKPath;
        photonLibraryPath = 'https://raw.githubusercontent.com/linkroom/linkroomv3_assets/main/assets/libs/Photon-Javascript_SDK.js';

        loadLibrary().then((Photon) => {
            client = new Photon.LoadBalancing.LoadBalancingClient(Photon.ConnectionProtocol.Wss, keys.networkingAppId, appVersion);


            if (token != null) {
                const uuid = uuidv4();
                var queryString = `user=${uuid}&token=${token}`;
                client.setCustomAuthentication(queryString, Photon.LoadBalancing.Constants.CustomAuthenticationType.Custom);
            }

            resolve();
        });
    })
}

export function joinRoom(roomName) {

    if (!client)
        return;

    let roomOptions = {
        createIfNotExists: true
    };

    client.joinRoom(roomName, roomOptions);
}

export function connectToMaster() {

    const isConnected = client.connectToRegionMaster(region);

    if (isConnected) {

        client.setLogLevel(Exitgames.Common.Logger.Level.OFF);

        client.onStateChange = onStateChange;

        client.onEvent = onEvent;
        client.onActorJoin = onActorJoin;
        client.onActorLeave = onActorLeave;
        client.onMyRoomPropertiesChange = onRoomPropertiesChange;
        client.onError = onError;
        client.onServerErrorInfo = onServerErrorInfo;
        client.onActorPropertiesChange = onActorPropertiesChange;

    } else {
        console.log('NETWORKING: error connecting to master');
    }
}

export function disconnect() {
    client.disconnect();
}

export function reconnectToMaster() {
    return client.reconnectToMaster();
}

export function getRoomCustomProperty(name) {
    return client.myRoom().getCustomProperty(name);
}

export function setRoomCustomProperty(name, value) {
    client.myRoom().setCustomProperty(name, value);
}

export function getRoomOtherActors() {
    if (client && client.myRoom()) {
        const myActorId = client.myActor().userId;
        const allActors = client.myRoomActors();
        const otherActors = Object.values(allActors).filter(actor => actor.userId !== myActorId);
        return otherActors;
    } else {
        console.error("Not connected to a room.");
        return [];
    }
}

export function getActors() {
    const allActors = client.myRoomActors();
    const otherActors = Object.values(allActors);
    return otherActors;
}

export function searchActorByUserId(userId) {
    var actors = client.myRoomActors();
    for (var clave in actors) {
        if (actors.hasOwnProperty(clave)) {
            if (actors[clave].userId === userId)
                return actors[clave];
        }
    }
}

export function getLocalActor() {
    return localActor;
}

export function sendMessage(code, msg, actorsNr) {
    if (actorsNr)
        client.raiseEvent(code, msg, { targetActors: actorsNr, receivers: Photon.LoadBalancing.Constants.ReceiverGroup.Others });
    else
        client.raiseEvent(code, msg);
}

function onActorJoin(actor) {

    if (actor.isLocal) {
        console.log('NETWORKING: joined room');
        localActor = actor;

        const actors = client.myRoomActors();
        for (const key in actors) {
            if (Object.hasOwnProperty.call(actors, key)) {
                const roomActor = actors[key];
                if (roomActor.actorNr != localActor.actorNr) {
                    otherActors[roomActor.actorNr] = roomActor;
                }
            }
        }
    } else {
        otherActors[actor.actorNr] = actor;
    }


    emit(EVENT.ACTOR_JOIN, actor);
}

function onActorLeave(data) {
    if (otherActors[data.actorNr])
        delete otherActors[data.actor];

    emit(EVENT.ACTOR_LEAVE, data);
}

function onJoinedLobby() {
    emit(EVENT.JOINED_LOBBY, {});
}

function onEvent(code, content, actorNr) {
    switch (code) {
        case 0:
            break;
    }
    emit(EVENT.RECEIVE_MESSAGE, { code, content, actorNr });
}

function onRoomPropertiesChange() {
    emit(EVENT.ROOM_PROPERTIES_CHANGE, {});
}

function onError(errorCode, errorMsg) {
    emit(EVENT.ERROR, { errorCode, errorMsg });
}

function onServerErrorInfo(info) {
    console.log(info);

}

function onActorPropertiesChange(actor, properties) {
    if (actor.onPropertyChange) {
        actor.onPropertyChange();
    }
};

export function setActorCustomProperty(_actor, name, value) {
    _actor.setCustomProperty(name, value);
}

export function getActorCustomProperty(_actor, name) {
    return _actor.getCustomProperty(name);
}

export function searchActorByActorNr(actorNr) {
    var actors = client.myRoomActors();
    for (var clave in actors) {
        if (actors.hasOwnProperty(clave)) {
            if (actors[clave].actorNr === actorNr)
                return actors[clave];
        }
    }
}

function onStateChange(stateCode) {
    const stateName = Photon.LoadBalancing.LoadBalancingClient.StateToName(stateCode);

    // console.log('NETWORKING', 'state', stateName);

    switch (stateName) {
        case 'JoinedLobby':
            onJoinedLobby();
            break;
        case 'Error':
            console.log('NETWORKING', 'state', stateName);
            //emit(EVENT.ERROR);
            break;
        case "Joined":
            verifyConnection();
            break;
        default:
            break;
    }

}

function verifyConnection() {
    setInterval(() => {
        var actorNumber = client.myActor().actorNr;
        sendMessage(0, `Actor Nr( ${actorNumber} ) is connected`);
    }, 3000);
}

export function isJoinedToRoom() {
    return client.isJoinedToRoom();
}

function loadLibrary() {

    return new Promise((resolve, reject) => {
        fetch(photonLibraryPath)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.text();
            })
            .then(data => {
                const script = document.createElement('script');
                script.textContent = data;
                document.head.appendChild(script);
                resolve(Photon);
            })
            .catch(error => {
                console.error('Error al cargar el script:', error);
            });
    });


}
