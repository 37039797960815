export class TaskQueue {
    constructor(limit) {
        this.limit = limit;
        this.currentlyRunning = 0; 
        this.queue = []; 
    }

    
    enqueue(taskFn) {
        return new Promise((resolve, reject) => {
            const executeTask = () => {
                this.currentlyRunning++;
                taskFn()
                    .then((result) => {
                        resolve(result);
                    })
                    .catch((err) => {
                        console.log('Task Queue reject');
                        reject(err);
                    })
                    .finally(() => {
                        this.currentlyRunning--;
                        this.runNextTask();
                    });
            };

            if (this.currentlyRunning < this.limit) {
                executeTask();
            } else {
                this.queue.push(executeTask);
            }
        });
    }

    runNextTask() {
        if (this.queue.length > 0 && this.currentlyRunning < this.limit) {
            const nextTask = this.queue.shift();
            nextTask();
        }
    }
}