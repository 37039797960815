<div class="modal-loading">
  <div class="container-modal-loading">
    <div class="modal-layer-loading"></div>
    <div class="modal-content-loading">
      <h2>{{ title }}</h2>
      @if (message) {
        <p>{{ message }}</p>
      }
      @if (loading) {
        <img src="assets/images/icon-loading.gif" />
      } @else {
        <br />
      }
    </div>
  </div>
</div>
