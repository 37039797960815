import { TransformControls } from 'three/addons/controls/TransformControls.js';
import { Constants } from './constants';

var scene;
var camera;
var inputElement;
var transformControls;
var sceneObjects = [];
var currentSceneObject = undefined;

var events = {};

var enableTransformControls = true;

export var subscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var unsubscribe = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

var emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}

let transformControlsGizmo;
export function initTransform(_scene, _camera, _inputElement) {
    scene = _scene;
    inputElement = _inputElement;
    camera = _camera;

    transformControls = new TransformControls(camera, inputElement);
    transformControls.setSpace('local');

    transformControls.addEventListener('mouseDown', (event) => {
        emit(Constants.EVENTS_NAME.MODIFY_OBJECT, false);
    });

    transformControls.addEventListener('objectChange', (event) => {

        if (!currentSceneObject) return;

        switch (transformControls.getMode()) {
            case "scale":
                switch (transformControls.axis) {
                    case 'Y':
                        currentSceneObject.scale.set(currentSceneObject.scale.y, currentSceneObject.scale.y, currentSceneObject.scale.y);
                        break;
                    case 'X':
                        currentSceneObject.scale.set(currentSceneObject.scale.x, currentSceneObject.scale.x, currentSceneObject.scale.x);
                        break;
                    case 'Z':
                        currentSceneObject.scale.set(currentSceneObject.scale.z, currentSceneObject.scale.z, currentSceneObject.scale.z);
                        break;
                    default:
                        break;
                }


                break;
        }

        emit(Constants.EVENTS_NAME.CHANGE_SCENEOBJECT,
            {
                mode: transformControls.getMode(),
                uuid: currentSceneObject.userData.uuid,
                group: currentSceneObject.userData.group,
                position: currentSceneObject.position.toArray(),
                rotation: [currentSceneObject.rotation.x, currentSceneObject.rotation.y, currentSceneObject.rotation.z],
                scale: currentSceneObject.scale.toArray(),
            }
        );

    })

    transformControls.addEventListener('mouseUp', (event) => {
        emit(Constants.EVENTS_NAME.APPLY_OBJECT_MODIFICATION, true);
    });

    inputElement.addEventListener('keydown', (event) => {
        if (currentSceneObject) {
            switch (event.code) {
                case 'KeyT':
                    setMode();
                    break;
                case 'KeyE':
                    setMode(Constants.MODE.scale);
                    break;
                case 'KeyR':
                    setMode(Constants.MODE.rotate);
                    break;
                default:
                    break;
            }
        }
    });

    transformControlsGizmo = transformControls.getHelper();
    scene.add(transformControlsGizmo);
}

export function addSceneObject(obj) {
    sceneObjects.push(obj);
}

export function removeSceneObject(obj) {

    let index = sceneObjects.indexOf(obj);

    if (index !== -1) {
        sceneObjects.splice(index, 1);
    }

    transformControls.detach();
}

export function setMode(mode = Constants.MODE.translate) {
    if (enableTransformControls == true) {
        transformControls.setMode(mode);
    }
}

export function modifyObject(uuid) {
    currentSceneObject = sceneObjects.find(obj => obj.userData.uuid === uuid);

    if (currentSceneObject === undefined) return;
    enableAndDisableTransformControls();
    setMode();
}

function enableAndDisableTransformControls() {
    if (enableTransformControls == true) {
        transformControlsGizmo.enabled = true;
        transformControlsGizmo.visible = true;
        transformControls.attach(currentSceneObject);
    } else {
        transformControlsGizmo.enabled = false;
        transformControlsGizmo.visible = false;
        transformControls.detach();
    }
}

export function setEnableTransformControls(_enableTransformControls) {
    enableTransformControls = _enableTransformControls

    if (currentSceneObject === undefined) return;
    enableAndDisableTransformControls();
}

export function getSceneObject(uuid) {

    let sceneObject = sceneObjects.find(obj => obj.userData.uuid === uuid);
    return sceneObject;
}

export function hideTransformControl() {
    transformControlsGizmo.enabled = false;
    transformControlsGizmo.visible = false;
}

export function getCurrentSceneObject() {
    return currentSceneObject;
}

