import * as THREE from 'three';
import { Constants } from './constants';
import TWEEN from '@tweenjs/tween.js'

let targetPos = undefined;
let thereIsTargetValid = false;
let pathPoints = new Array();
let pointer;
var sceneLayer = 1;
let raycaster;
var camera;
var scene;


var indexPoint = 0;
var guide;
var tween;
var h = 0.35;

var events = {};

var enableInputs = true;

export var subscribe = (_event, listener) => {
    if (!events[_event]) {
        events[_event] = [];
    }
    events[_event].push(listener);
}

export var unsubscribe = (_event, listener) => {
    if (events[_event]) {
        events[_event] = events[_event].filter((existingListener) => {
            return existingListener !== listener;
        });
    }
}

var emit = (_event, data) => {
    if (events[_event]) {
        events[_event].forEach((listener) => {
            listener(data);
        });
    }
}


export function init(cam) {
    camera = cam;
}

export function initPath(_scene, _sceneLayer) {
    scene = _scene;
    sceneLayer = _sceneLayer;
    raycaster = new THREE.Raycaster();
    raycaster.layers.set(sceneLayer);
    pointer = new THREE.Vector2();

    const geometry = new THREE.SphereGeometry(0.1, 12, 12);
    const material = new THREE.MeshBasicMaterial({ color: 0x0091ff, transparent: true, opacity: 0.5 });
    guide = new THREE.Mesh(geometry, material);

}

export function updatePointer(posX, posY) {
    pointer.set(posX, posY);
}

export function findAPath(mousePosX, mousePosY) {

    if (enableInputs == false) return;

    raycaster.setFromCamera(pointer, camera);
    const intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length > 0) {
        let point = intersects[0].point;
        let lookAtTarget = intersects[0].face.normal;

        // let normal = intersects[0].normal.clone();
        // let terrainNormal = new THREE.Vector3(0, 1, 0);
        // let dotproduct = normal.dot(terrainNormal);
        // if (dotproduct >= 0.86) {
        //     sphere.material.color = new THREE.Color(0xffff00);
        // } else {
        //     sphere.material.color = new THREE.Color(0xff0000);
        // }

        // if (dotproduct < 0.86)
        //     return;

        moveTo(point, lookAtTarget);
    }
}

export function enableControls(value) {
    enableInputs = value;
}

export function moveTo(point, lookAtTarget = new THREE.Vector3(0, 1, 0)) {

    reset();

    let sphere = createSphere(point, 0.1, new THREE.Color(0xffff00));

    if (point.y >= 0.86) {
        sphere.material.color = new THREE.Color(0xffff00);
    } else {
        sphere.material.color = new THREE.Color(0xff0000);
    }

    if (point.y >= 0.1)
        return;


    let startPoint = projectPoint(camera.position.clone(), new THREE.Vector3(0, -1, 0));
    startPoint.add(new THREE.Vector3(0, h, 0));

    let endPos = point.clone();
    endPos.add(new THREE.Vector3(0, h, 0));

    createSphere(startPoint, 0.03, new THREE.Color(0x0000ff));

    createPath(startPoint, endPos);

    createSphere(endPos, 0.03, new THREE.Color(0x0000ff));
    addPointToPath(endPos);
    var data = {
        visible: true,
        pos: new THREE.Vector3(endPos.x, endPos.y - h, endPos.z),
        lookAlt: lookAtTarget
    }
    emit(Constants.EVENTS_NAME.POINTER_POSITION, data)

    processPathPoints();

}

function createSphere(pos, r, color) {
    const geometry = new THREE.SphereGeometry(r, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color });
    const sphere = new THREE.Mesh(geometry, material);
    // scene.add(sphere);
    sphere.position.copy(pos);

    return sphere;
}

function createLine(initialPos, endPos, color) {
    let array = new Array();
    array.push(initialPos);
    array.push(endPos);
    let geometry = new THREE.BufferGeometry().setFromPoints(array);
    let line = new THREE.Line(geometry, new THREE.MeshBasicMaterial({ color }));
    // scene.add(line);
    return line;
}

function projectPoint(point, direction) {
    let raycaster = new THREE.Raycaster(point, direction);
    raycaster.layers.set(sceneLayer);
    let intersects = raycaster.intersectObjects(scene.children);
    if (intersects.length > 0) {
        return intersects[0].point;
    } else {
        return null;
    }
}

function addPointToPath(point) {
    pathPoints.push(point);
}

function createPath(startPoint, endPoint) {

    let verticalPoints = verifyVerticalCollisionByAngles(startPoint, endPoint);
    if (verticalPoints.points) {
        if (!verticalPoints.slope) {
            let collidePoint = verifyForwardCollision(verticalPoints.points);
            if (collidePoint) {
                let nearSidePoint = createHorizontalCorner(startPoint, endPoint, collidePoint);
                createSphere(nearSidePoint, 0.03, new THREE.Color(0x0000ff));
                addPointToPath(nearSidePoint);

                createPath(startPoint, nearSidePoint);
                createPath(nearSidePoint, endPoint);
            } else {
                // console.log('nothing');
                createLine(startPoint, endPoint, new THREE.Color(0x00ff00));
            }
        } else {
            let midleCornerPoint = createVerticalCorner(startPoint, endPoint, verticalPoints.angle, verticalPoints.angleDirection, verticalPoints.minimunDistance);
            createSphere(midleCornerPoint, 0.03, new THREE.Color(0x0000ff));



            createPath(startPoint, midleCornerPoint);
            createPath(midleCornerPoint, endPoint);
        }
    } else {

    }

}

function verifyVerticalCollisionByAngles(initialPos, endPoint) {
    let forwardDirection = endPoint.clone().sub(initialPos).normalize();
    let distance = endPoint.distanceTo(initialPos);
    let raycasterRightPoints = null;
    let raycasterLeftPoints = null;

    let stepsAngle = 0.0001;
    let slope = false;
    let angle = 0;
    let angleDirection = -1;
    let minimunDistance = 999999;
    let midleSlopePoint = null;

    for (let index = 0; index < Math.PI / 2; index += stepsAngle) {
        let rightBridge = forwardDirection.clone();
        rightBridge.applyEuler(new THREE.Euler(0, -index, 0, 'XYZ'));
        rightBridge.normalize();

        let leftBridge = forwardDirection.clone();
        leftBridge.applyEuler(new THREE.Euler(0, index, 0, 'XYZ'));
        leftBridge.normalize();

        angle = index;

        let rightEndPos = initialPos.clone();
        rightBridge.multiplyScalar(distance);
        rightEndPos.add(rightBridge);

        let leftEndPos = initialPos.clone();
        leftBridge.multiplyScalar(distance);
        leftEndPos.add(leftBridge);


        // createLine(initialPos,rightEndPos,new THREE.Color(0x0000ff));
        raycasterRightPoints = placeVerticalRaycasters(initialPos, rightEndPos);


        if (!raycasterRightPoints.arrayPos) {
            midleSlopePoint = raycasterRightPoints.midleSlopePoint;

            if (stepsAngle == 0.0001) {
                let dist = initialPos.distanceTo(midleSlopePoint);
                stepsAngle = 0.2 / dist;
            }

            // createLine(initialPos,leftEndPos,new THREE.Color(0x0000ff));
            raycasterLeftPoints = placeVerticalRaycasters(initialPos, leftEndPos);

            if (!raycasterLeftPoints.arrayPos) {
                midleSlopePoint = raycasterLeftPoints.midleSlopePoint;
                slope = true;
            } else {
                angleDirection = 1;
                let dist = initialPos.distanceTo(midleSlopePoint);
                if (minimunDistance > dist)
                    minimunDistance = dist;
                return { points: raycasterLeftPoints, slope, angle, angleDirection, minimunDistance };
            }
        } else {
            angleDirection = -1;
            if (slope) {
                let dist = initialPos.distanceTo(midleSlopePoint);
                if (minimunDistance > dist)
                    minimunDistance = dist;
            }
            return { points: raycasterRightPoints.arrayPos, slope, angle, angleDirection, minimunDistance };
        }


    }

    return { points: null, slope, angle, angleDirection, minimunDistance };
}

function verifyForwardCollision(points) {
    let mainDirection = points[1].clone().sub(points[0].clone());

    let rightBridge = mainDirection.clone();
    rightBridge.applyEuler(new THREE.Euler(0, -Math.PI / 2, 0, 'XYZ'));
    rightBridge.normalize();


    let rightEndPos = points[0].clone();
    rightBridge.multiplyScalar(0.25);
    rightEndPos.add(rightBridge);

    // postMessage({ type: 'createSphere', pos:rightEndPos,r: 0.01,color:new THREE.Color(0x00ff00)});

    let raycasterR = new THREE.Raycaster(rightEndPos, mainDirection);
    const intersectsR = raycasterR.intersectObjects(scene.children);

    if (intersectsR.length > 0) {
        let distanceToTarget = points[points.length - 1].distanceTo(points[0]);
        let distanceToPoint = intersectsR[0].point.distanceTo(points[0]);

        // console.log(distanceToTarget,distanceToPoint);

        createSphere(intersectsR[0].point, 0.02, new THREE.Color(0xff0000));
    } else {
        console.log('NOT INTERSECT');
    }

    let raycasterNP = new THREE.Raycaster();
    let raycasterSp = new THREE.Raycaster();
    for (let index = 0; index <= points.length; index++) {
        const point = points[index];
        const nextPoint = points[index + 1];

        createSphere(point, 0.01, new THREE.Color(0x00ff00));
        if (!nextPoint)
            break;

        raycasterNP.set(nextPoint, new THREE.Vector3(0, -1, 0));
        raycasterNP.layers.set(sceneLayer);
        const intersectsNP = raycasterNP.intersectObjects(scene.children);

        if (intersectsNP.length > 0) {
            const nextPointGround = intersectsNP[0].point;
            let direction = nextPointGround.clone().sub(point).normalize();
            raycasterSp.set(point, direction);
            raycasterSp.layers.set(sceneLayer);

            const intersects = raycasterSp.intersectObjects(scene.children);

            if (intersects.length > 0) {
                let collidePoint = intersects[0].point;
                let normal = intersects[0].normal.clone();
                let nextNormal = nextPoint ? intersectsNP[0].normal.clone() : new THREE.Vector3(0, 1, 0);
                let dotproduct = normal.dot(nextNormal);

                // let sphere = createSphere(collidePoint,0.03, new THREE.Color(0x000000));

                if (dotproduct >= 0.86) {
                    // sphere.material.color = new THREE.Color(0x00ff00);
                } else {
                    // sphere.material.color = new THREE.Color(0xff0000);

                    return collidePoint;
                }
            }
        }
    }
}

function createHorizontalCorner(initialPos, endPoint, collidePoint) {
    let forwardDirection = endPoint.clone().sub(initialPos).normalize();
    let bodyWidth = 0.3;
    let byPassAngle = 0;
    // let rightByPassPreviousDistance, leftByPassPreviousDistance = 0;
    // let angle = 0;
    let angleDirection = -1;
    let lastSidePoint = null;
    let stepsAngle = 0;

    let dist = initialPos.distanceTo(collidePoint);
    stepsAngle = bodyWidth / dist;

    for (let index = 0; index < Math.PI / 2; index += stepsAngle) {

        if (stepsAngle == 0) {

        } else {
            let rightBridge = forwardDirection.clone();
            rightBridge.applyEuler(new THREE.Euler(0, -index, 0, 'XYZ'));
            rightBridge.normalize();

            let rightBridgePlus = forwardDirection.clone();
            rightBridgePlus.applyEuler(new THREE.Euler(0, -(index + stepsAngle), 0, 'XYZ'));
            rightBridgePlus.normalize();

            let rightPoint = projectPoint(initialPos, rightBridge);
            let rightPointPlus = projectPoint(initialPos, rightBridgePlus);
            rightPoint = rightPoint ? rightPoint : new THREE.Vector3(0, 0, 0);
            rightPointPlus = rightPointPlus ? rightPointPlus : new THREE.Vector3(99999, 9999, 9999);
            let distance1Plus = rightPoint.distanceTo(initialPos);
            let distance2Plus = rightPointPlus.distanceTo(initialPos);

            byPassAngle = index;

            if (distance2Plus - distance1Plus > 1) {
                angleDirection = -1;
                lastSidePoint = rightPoint.clone();
                break;
            } else {
                let leftBridge = forwardDirection.clone();
                leftBridge.applyEuler(new THREE.Euler(0, index, 0, 'XYZ'));
                leftBridge.normalize();

                let leftBridgePlus = forwardDirection.clone();
                leftBridgePlus.applyEuler(new THREE.Euler(0, (index + stepsAngle), 0, 'XYZ'));
                leftBridgePlus.normalize();

                let leftPoint = projectPoint(initialPos, leftBridge);
                let leftPointPlus = projectPoint(initialPos, leftBridgePlus);
                leftPoint = leftPoint ? leftPoint : new THREE.Vector3(0, 0, 0);
                leftPointPlus = leftPointPlus ? leftPointPlus : new THREE.Vector3(99999, 9999, 9999);
                let leftDistance1Plus = leftPoint.distanceTo(initialPos);
                let leftDistance2Plus = leftPointPlus.distanceTo(initialPos);

                if (leftDistance2Plus - leftDistance1Plus > 1) {
                    angleDirection = 1;
                    lastSidePoint = leftPoint.clone();
                    break;
                }

            }

        }

    }

    let sidePoint = initialPos.clone();
    let direction = forwardDirection.clone();
    let distanceToLastSidePoint = lastSidePoint.distanceTo(initialPos);

    direction.applyEuler(new THREE.Euler(0, (byPassAngle + stepsAngle * 1.5) * angleDirection, 0, 'XYZ'));
    direction.normalize();
    direction.multiplyScalar(distanceToLastSidePoint);
    sidePoint.add(direction);

    return sidePoint;

}

function placeVerticalRaycasters(initialPos, endPoint) {
    let bodyWidth = 0.8;
    let iterations = 32;

    let direction = endPoint.clone();

    direction.sub(initialPos);
    direction.normalize();

    iterations = initialPos.distanceTo(endPoint) / bodyWidth;
    iterations = parseInt(iterations);

    let slope = false;
    let arrayPos = new Array();
    let firstSlopePoint = null;
    let secondSlopePoint = null;
    let midleSlopePoint = null;

    let raycasterSp = new THREE.Raycaster();
    let lastRaycasterSp = new THREE.Raycaster();

    for (let index = 1; index <= iterations; index++) {
        let pos = initialPos.clone();
        let lastPos = pos.clone();

        let vectorDisplacement = direction.clone();
        vectorDisplacement.multiplyScalar(bodyWidth * index);

        pos.add(vectorDisplacement);

        let vectorDisplacementLast = direction.clone();
        vectorDisplacementLast.multiplyScalar(bodyWidth * (index - 1));

        lastPos.add(vectorDisplacementLast);
        raycasterSp.set(pos.clone().add(new THREE.Vector3(0, initialPos.y + 1.2, 0), 0), new THREE.Vector3(0, -1.2, 0));
        lastRaycasterSp.set(lastPos.clone().add(new THREE.Vector3(0, initialPos.y + 1.2, 0), 0), new THREE.Vector3(0, -1.2, 0));
        raycasterSp.layers.set(sceneLayer);
        lastRaycasterSp.layers.set(sceneLayer);

        const intersects01 = lastRaycasterSp.intersectObjects(scene.children);
        const intersects02 = raycasterSp.intersectObjects(scene.children);

        if (intersects02.length > 0) {
            if (intersects01.length > 0) {
                let point01 = intersects01[0].point;
                let point02 = intersects02[0].point;
                let yDif = Math.abs(point02.y - point01.y);
                if (yDif > 2) {
                    // sp.material.color = new THREE.Color(0xff0000);
                    slope = true;

                    if (firstSlopePoint && !secondSlopePoint) {
                        secondSlopePoint = pos.clone();
                    }

                    if (!firstSlopePoint)
                        firstSlopePoint = lastPos.clone();
                    // break;                   
                } else {
                }
            }
        } else {
            // console.log('void');
        }

        arrayPos.push(pos);
    }

    if (slope) {
        midleSlopePoint = firstSlopePoint.clone();
        if (secondSlopePoint)
            midleSlopePoint.lerpVectors(firstSlopePoint, secondSlopePoint, 0.001);

    }

    if (slope) return { arrayPos: null, midleSlopePoint }; else return { arrayPos };
}

function processPathPoints() {
    if (pathPoints.length > 0 && indexPoint < pathPoints.length) {
        targetPos = pathPoints[indexPoint];
        targetPos.y = (targetPos.y - h)
        indexPoint++;
        thereIsTargetValid = true;

        guide.position.set(camera.position.x, targetPos.y, camera.position.z);
        var dis = targetPos.distanceTo(guide.position);
        var velocity = (dis * Constants.PLAYER.WALK_SPEED_ON_FLOOR) / 8;
        tween = new TWEEN.Tween(guide.position)
            .to(targetPos, velocity * 100)
            .easing(TWEEN.Easing.Linear.None)
            .onUpdate(() => {
                emit(Constants.EVENTS_NAME.MOVE_PLAYER_TO_POSITION, guide.position);
            })
            .onComplete(() => {
                processPathPoints();
            });
        tween.start();
        emit(Constants.EVENTS_NAME.LOOK_AT_TARGET, targetPos);

    } else {
        reset();
    }
}
function reset() {
    if (tween != null) tween.stop();
    pathPoints = [];
    indexPoint = 0;
    thereIsTargetValid = false;
    var data = {
        visible: false,
        pos: new THREE.Vector3(0, 0, 0),
        lookAlt: new THREE.Vector3(0, 0, 0)
    }
    emit(Constants.EVENTS_NAME.POINTER_POSITION, data)

}

export function cancelPathfindingMovement() {
    if (thereIsTargetValid == false) return;

    reset();
}

function createVerticalCorner(startPoint, endPoint, angle, angleDirection, distance) {
    let forwardDirection = endPoint.clone().sub(startPoint).normalize();
    let difAngle = 0.2 / distance;
    let newAngle = angleDirection * (angle + 1.5 * difAngle);

    let bridgeDirection = forwardDirection.clone();
    bridgeDirection.applyEuler(new THREE.Euler(0, newAngle, 0, 'XYZ'));
    bridgeDirection.normalize();

    let midleCornerPoint = startPoint.clone();
    bridgeDirection.multiplyScalar(distance);
    midleCornerPoint.add(bridgeDirection);

    return midleCornerPoint;
}

export function inMovement() {
    return thereIsTargetValid;
}

export function animate() {
    TWEEN.update();
}



