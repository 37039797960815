import { v4 as uuidv4 } from "uuid";
import {
    getSelectedSceneObject,
    getNavigationRaycast,
    forceUnselectSceneObject,
    orbitToSceneObject,
    exitOrbitMode,
} from "./navigationModuleController";
import {
    sendOnSceneObjectCreateOrUpdate,
    sendDeleteSceneObjectFromNetwork,
    SendVideoStatusToProjector as SendVideoStatusToProjectorByNetwork
} from "./networkingModuleController";
import {
    deleteSceneObject,
    loadGalleryLODObject,
    createInstancedMeshLODSceneObject,
    createInstancedMeshLODSceneObjectFromDB,
    createSceneObjectByBuffers,
    placeLoadingObject,
    placeSceneObject,
    remoteUpdateSceneObject,
    onSceneObjectSelect,
    onSceneObjectUpdated,
    resetProjector,
    remoteDeleteSceneObject,
    setVideoTextureToprojector,
    setRoomElementsForOrbit,
    createNotFoundObject
} from "./viewerModuleController";
import { MSG_CODE } from "../constants";
import { screenShare, stopScreenShare, communicationSubscribe } from "./communicationModuleController";

let options = {};
let objectsModule;
let viewerModule;
let navigationModule;

const nearFactor = 2.0;

export let objectsWorkerPromises = {};
const sceneObjects = {};

var sceneObjectVideoTemp = undefined;

var currentScreenShareData = null;

export function initObjectsModule(_options) {
    return new Promise(resolve => {
        options = _options;
        objectsModule = options.modules.objectsModule;
        viewerModule = options.modules.viewerModule;
        objectsModule.init(options).then(() => {
            resolve();
        });

        communicationSubscribe("stop-screen", () => {
            stopShareNavivePopup();
        });

    });
}

export function initObjectsOffscreen(_options) {
    options = _options;
    objectsModule = options.modules.objectsModule;
    viewerModule = options.modules.viewerModule;
    navigationModule = options.modules.navigationModule;
}

export function getGalleryObjects(filter = null) {
    return objectsModule.getGalleryObjects(filter);
}

export function registerSceneObject(data) {
    return objectsModule.registerSceneObject(data);
}

// on Offscreen context
export function onSelectSceneObject(sceneObject) {
    onSceneObjectSelect(sceneObject);

    const sceneObjectData = {
        uuid: sceneObject ? sceneObject.userData.uuid : null
    }

    let msg = {
        code: MSG_CODE.SCENE_OBJECT_ON_SELECT,
        data: sceneObject ? sceneObjectData : undefined
    }

    postMessage(msg);
}

export function onSceneObjectUpdate(sceneObject) {
    onSceneObjectUpdated(sceneObject);
    let msg = {
        code: MSG_CODE.SCENE_OBJECT_REGISTER_UPDATE,
        data: {
            data: {
                position: [
                    sceneObject.position.x,
                    sceneObject.position.y,
                    sceneObject.position.z],
                rotation: [
                    sceneObject.rotation.x,
                    sceneObject.rotation.y,
                    sceneObject.rotation.z],
                scale: [
                    sceneObject.scale.x,
                    sceneObject.scale.y,
                    sceneObject.scale.z],
            },
            uuid: sceneObject.userData.uuid
        }
    }
    postMessage(msg);
}

export function getSceneObject(uuid) {
    return objectsModule.getSceneObject(uuid);
}

export function loadVideoToSelectedProjector(obj) {

    var scenObjectUuid = obj.scenObjectUuid;
    var selectedProjector = objectsModule.getSceneObject(scenObjectUuid);

    if (selectedProjector) {
        var metadata = {
            assetId: sceneObjectVideoTemp.assetId,
            owner: selectedProjector.owner,
            ownerId: selectedProjector.ownerId,
            isPlaying: true,
            muted: false,
            loop: true,
        }
        if (selectedProjector.typeDisplay == "video") {
            removeVideo(selectedProjector);
        }
        stopScreenSharingBeforeNextAction(obj);

        SendVideoStatusToProjectorByNetwork({ uuid: selectedProjector.uuid, status: 'new', metadata })
        objectsModule.registerVideoToProyector(selectedProjector.uuid, metadata, "video").then((sceneObject) => {

            if (sceneObject.metadata) {
                switch (sceneObject.typeDisplay) {
                    case "video":
                        loadVideo(sceneObject);
                        break;
                }
            }
            sceneObjectVideoTemp = undefined;
        });
    }
}

export function loadSceneObject(obj) {

    return new Promise(async (resolve, reject) => {
        const sceneObject = await objectsModule.requestSceneObject(obj.id);
        if (!sceneObject) resolve();

        switch (sceneObject.type) {
            case 'video':

                sceneObjectVideoTemp = sceneObject;

                getSelectedSceneObject().then((data) => {
                    const obj = getSceneObject(data.uuid);

                    if (obj.data.isDisplay) {
                        loadVideoToSelectedProjector({ scenObjectUuid: obj.uuid });
                    } else {
                        var projectors = objectsModule.projectorsAvailableOnTheScene();
                        var data = {
                            code: "sceneProjector",
                            data: projectors
                        }
                        options.callbacks.defaultResponse(data);
                    }
                });

                break;
            case 'image':

                break;
            case 'model':

                loadModelSceneObject(sceneObject).then(resolve).catch(reject);

                break;
        }



    })
}

function loadModelSceneObject(sceneObject) {
    return new Promise(async (resolve, reject) => {
        const seed = uuidv4();
        if (!sceneObject.data.isDisplay) {
            if (uniques[sceneObject.assetId]) {
                //cached sceneObject
                const d = {
                    uuid: sceneObject.uuid,
                    assetId: sceneObject.assetId,
                    cached: true,
                    seed
                }
                createInstancedMeshLODSceneObject(d).then(() => {

                }).catch(e => {
                    console.log('Error trying to create cached scene object', e);
                })

            } else {
                //object is not loaded
                loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {

                    uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId };
                    const d = {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        cached: false,
                        buffers: buffers,
                        seed
                    }

                    createInstancedMeshLODSceneObject(d).then(() => {
                    }).catch(e => {
                        console.log('Error trying to create scene object', e);

                    })
                });
            }
        }

        getNavigationRaycast().then(intersection => {
            //Colocar Rotación dependiendo de la normal del raycast            
            placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: intersection.point, normal: intersection.normal }] }).then(() => {
                var registerData = {
                    assetId: sceneObject.assetId,
                    uuid: sceneObject.uuid,
                    seed,
                    owner: sceneObject.owner,
                    ownerId: sceneObject.ownerId,
                    data: {
                        name: sceneObject.data.name,
                        position: intersection.point,
                        rotation: [0, 0, 0],
                        scale: [1, 1, 1]
                    }
                }

                if (sceneObject.data.isDisplay) {
                    registerData.data.isDisplay = sceneObject.data.isDisplay;

                    displays[sceneObject.uuid] = registerData;

                    const display = displays[sceneObject.uuid];

                    loadGalleryLODObject({ uris: sceneObject.data.uris }).then((buffers) => {
                        const displayMaterialName = sceneObject.data.displayMaterialName
                        const d = {
                            data: {
                                uuid: display.uuid,
                                objectPosition: display.data,
                                assetId: display.assetId,
                                buffers,
                                displayMaterialName
                            }
                        }


                        createSceneObjectByBuffers(d).then(() => {
                            objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                                sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                                sendCallbacks(sceneObjectInDB, "add");
                                resolve();
                            })
                        });

                    }).catch(e => {
                        console.log(e);
                    });
                } else {

                    placeSceneObject({ data: { uuid: sceneObject.uuid, assetId: sceneObject.assetId, position: intersection.point, normal: intersection.normal, seed } }).then((objectRotation) => {
                        registerData.data.rotation = objectRotation.res;

                        objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                            sceneObjectInDB.seed = seed;
                            sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                            sendCallbacks(sceneObjectInDB, "add");
                            resolve();
                        });
                    }).catch((e) => {

                        uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId, seed };

                        addObjectPosition(sceneObject.assetId, uniques[sceneObject.assetId].groups, { position: registerData.data.position, name: registerData.data.name, rotation: [0, 0, 0], scale: registerData.data.scale, uuid: sceneObject.uuid }, seed);

                        delete uniques[sceneObject.assetId].buffers;
                        const notFound = {
                            data: uniques[sceneObject.assetId],
                        }
                        createNotFoundObject(notFound).then(() => {
                            objectsModule.registerSceneObject(registerData).then((sceneObjectInDB) => {
                                sceneObjectInDB.status = "removed";
                                sceneObjectInDB.seed = seed;
                                sendOnSceneObjectCreateOrUpdate(sceneObjectInDB);
                                sendCallbacks(sceneObjectInDB, "add");
                                // reject();
                            });

                        });
                    });
                }


            }).catch(reject);
        }).catch(reject);
    })
}

export function loadSceneObjectFromNetwork(sceneObject) {
    return new Promise(async (resolve, reject) => {
        const galleryObject = await objectsModule.requestSceneObject(sceneObject.assetId, sceneObject.owner, sceneObject.ownerId);
        if (!galleryObject) resolve();

        if (!sceneObject.data.isDisplay) {

            if (uniques[sceneObject.assetId]) {
                //cached sceneObject
                const d = {
                    uuid: sceneObject.uuid,
                    assetId: sceneObject.assetId,
                    cached: true,
                    seed: sceneObject.seed,
                    fromNetwork: true
                }
                createInstancedMeshLODSceneObject(d).then(() => {
                    // console.log('created Instanced Mesh LOD Scene Object');
                }).catch((e) => {
                    console.log('error cache', e);

                })

            } else {
                //object is not loaded
                loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                    uniques[galleryObject.assetId] = { groups: {}, assetId: galleryObject.assetId };
                    const d = {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        cached: false,
                        buffers: buffers,
                        seed: sceneObject.seed,
                        fromNetwork: true
                    }

                    createInstancedMeshLODSceneObject(d).then(() => {
                        // console.log('created Instanced Mesh LOD Scene Object');
                    }).catch((e) => {
                        console.log('error no cache', e);
                    });
                });
            }
        }

        placeLoadingObject({ data: [{ uuid: sceneObject.uuid, position: sceneObject.data.position, rotation: sceneObject.data.rotation }] }).then(() => {
            if (sceneObject.data.isDisplay) {
                displays[sceneObject.uuid] = sceneObject;
                const display = displays[sceneObject.uuid];
                loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                    const displayMaterialName = sceneObject.data.displayMaterialName
                    const d = {
                        data: {
                            uuid: display.uuid,
                            objectPosition: display.data,
                            assetId: display.assetId,
                            buffers,
                            displayMaterialName
                        }
                    }
                    createSceneObjectByBuffers(d).then(() => {
                        objectsModule.remoteRegisterSceneObject(sceneObject);
                        sendCallbacks(sceneObject, "add");
                    });

                }).catch(e => {
                    console.log(e);
                });

            } else {
                const placeData = {
                    data: {
                        uuid: sceneObject.uuid,
                        assetId: sceneObject.assetId,
                        position: sceneObject.data.position,
                        rotation: sceneObject.data.rotation,
                        seed: sceneObject.seed,
                    },
                }

                placeSceneObject(placeData).then(() => {
                    objectsModule.remoteRegisterSceneObject(sceneObject);
                    sendCallbacks(sceneObject, "add");
                }).catch((e) => {
                    uniques[sceneObject.assetId] = {
                        groups: {},
                        assetId: sceneObject.assetId,
                        seed:sceneObject.seed,
                    };

                    addObjectPosition(
                        sceneObject.assetId,
                        uniques[sceneObject.assetId].groups,
                        {
                            position: sceneObject.data.position,
                            name: sceneObject.data.name,
                            rotation: sceneObject.data.rotation,
                            scale: sceneObject.data.scale,
                            uuid: sceneObject.uuid,
                        },
                        sceneObject.seed,
                    );

                    delete uniques[sceneObject.assetId].buffers;
                    const notFound = {
                        data: uniques[sceneObject.assetId],
                    };
                    
                    createNotFoundObject(notFound).then(() => {
                        const sceneObjectInDB = sceneObject;
                        sceneObjectInDB.status = "removed";
                        objectsModule.remoteRegisterSceneObject(sceneObject);
                        sendCallbacks(sceneObject, "add");
                    })
                    .catch((e) => {
                        uniques[sceneObject.assetId] = {
                            groups: {},
                            assetId: sceneObject.assetId,
                            seed: sceneObject.seed,
                        };

                        addObjectPosition(
                            sceneObject.assetId,
                            uniques[sceneObject.assetId].groups,
                            {
                                position: sceneObject.data.position,
                                name: sceneObject.data.name,
                                rotation: sceneObject.data.rotation,
                                scale: sceneObject.data.scale,
                                uuid: sceneObject.uuid,
                            },
                            sceneObject.seed,
                        );

                        delete uniques[sceneObject.assetId].buffers;
                        const notFound = {
                            data: uniques[sceneObject.assetId],
                        };
                        createNotFoundObject(notFound).then(() => {
                            const sceneObjectInDB = sceneObject;
                            sceneObjectInDB.status = "removed";
                            objectsModule.remoteRegisterSceneObject(sceneObject);
                            sendCallbacks(sceneObjectInDB, "add");
                            // reject();
                        });
                    });
                });
            }
        });

    });


}

export function updateSceneObjectFromNetwork(sceneObject) {
    return new Promise((resolve, reject) => {
        // console.log('updateSceneObjectFromNetwork', sceneObject);

        objectsModule.remoteRegisterSceneObject(sceneObject);

        remoteUpdateSceneObject({ data: sceneObject }).then(() => {
            resolve();
        }).catch(reject);


    });
}

export function sceneObjectAction(action) {
    const msg = {
        code: MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE,
        data: {
            action,
        },
    };

    switch (action) {
        case "rotate":
        case "translate":
        case "scale":
            msg.code = MSG_CODE.NAVIGATION_TRANSFORM_CONTROLS_SET_MODE;

            if (options.rendererOptions.useOffscreen) {
                options.workers.offscreenCanvasWorker.postMessage(msg);
            } else {
                // TODO: check
            }

            break;
        case "detail":
            break;
        case "delete":


            getSelectedSceneObject().then((userData) => {
                deleteSceneObject({ data: userData.uuid }).then(() => {
                    forceUnselectSceneObject().then();
                    objectsModule.deleteSceneObject(userData.uuid).then(() => {
                        sendDeleteSceneObjectFromNetwork(userData);
                        sendCallbacks(userData.uuid, "remove");
                    });
                })
            });

            break;
        case "orbit":
            getSelectedSceneObject().then((data) => {
                orbitToSceneObject({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: false } }).then();
            });
            break;

        case "exitOrbit":
            getSelectedSceneObject().then((data) => {
                exitOrbitMode({ data: { uuid: data.uuid } }).then();
                setRoomElementsForOrbit({ data: { uuid: data.uuid, isVisible: true } }).then();
            });
            break;

        case 'copy':
            getSelectedSceneObject().then((userData) => {
                const obj = getSceneObject(userData.uuid);
                forceUnselectSceneObject().then();
                loadSceneObject({ id: obj.assetId }).then();
            });
            break;
        case 'pause':
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.isPlaying = false;
                SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'pause' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    pauseVideo(sceneObject);
                });
            });
            break;

        case "play":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.isPlaying = true;
                SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'play' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    playVideo(sceneObject);
                });
            });
            break;

        case "mute":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.muted = true;
                SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'mute' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    mutedVideo(sceneObject);
                });
            });
            break;

        case "unmute":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.muted = false;
                SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'unmute' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    mutedVideo(sceneObject);
                });
            });
            break;

        case "disableLoop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.loop = false;
                SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'disableLoop' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    loopVideo(sceneObject);
                });
            });
            break;

        case "enableLoop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                obj.metadata.loop = true;
                SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'enableLoop' });
                objectsModule.registerVideoToProyector(obj.uuid, obj.metadata, "video").then((sceneObject) => {
                    loopVideo(sceneObject);
                });
            });
            break;

        case "stop":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                if (obj.metadata) {

                    removeVideo(obj);
                    setTimeout(() => {
                        objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
                            var displayMaterialName = projectorData.data.displayMaterialName;
                            SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stop', displayMaterialName });
                            resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => {
                                objectsModule.registerVideoToProyector(obj.uuid, undefined, "none").then((sceneObject) => { });
                            });
                        });

                    }, 500)
                }
            });
            break;

        case "share":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                var metadata = {
                    isPlaying: true
                }

                objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
                    if (projectorData) {
                        var displayMaterialName = projectorData.data.displayMaterialName;
                        var uuid = obj.uuid;
                        screenShare().then((videoElement) => {
                            sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, obj.assetId);
                            currentScreenShareData = {
                                uuid,
                                displayMaterialName,
                                userID: options.userOptions.userId,
                                sceneobject: obj
                            }
                            obj.metadata = metadata;
                            obj.typeDisplay = "screenShare";
                            SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'share', displayMaterialName, currentScreenShareData });
                        });
                    }
                });

            });
            break;

        case "stopshare":
            getSelectedSceneObject().then((data) => {
                const obj = getSceneObject(data.uuid);
                stopScreenShareProjector(obj);
            });
            break;

        case "info":
            getSelectedSceneObject().then((data) => {
                objectsModule.getObjectById(data.assetId).then(async (assets) => {
                    var response = { uuid: data.uuid };

                    if (assets.metadata?.length > 0) {
                        var metadata = JSON.parse(JSON.stringify(assets.metadata));
                        for (const data of metadata) {
                            switch (data.type) {
                                case "Video":
                                case "Image":
                                case "AR Model":
                                    var url = await objectsModule.getUrlByFileName(data.value);
                                    data.value = url;
                                    break;
                            }
                        }
                        response.metadata = metadata;
                    }
                    sendCallbacks(response, "info");
                });
            });
            break;

        default:
            break;
    }
}

function stopShareNavivePopup() {
    if (currentScreenShareData) {
        const obj = currentScreenShareData.sceneobject;
        stopScreenShareProjector(obj);
    }
}

function stopScreenSharingBeforeNextAction(obj) {
    if (currentScreenShareData) {
        if (currentScreenShareData.userID == options.userOptions.userId) {
            stopScreenShare().then(() => { });
            obj.metadata = undefined;
            obj.typeDisplay = "none";
        }
    }
}

function stopScreenShareProjector(obj) {
    objectsModule.getObjectById(obj.assetId, obj.owner, obj.ownerId).then((projectorData) => {
        var displayMaterialName = projectorData.data.displayMaterialName;
        stopScreenShare().then(() => {
            resetProjector({ data: { uuid: obj.uuid, displayMaterialName } }).then((sceneObject) => { });
            currentScreenShareData = null;
            obj.metadata = undefined;
            obj.typeDisplay = "none";
            SendVideoStatusToProjectorByNetwork({ uuid: obj.uuid, status: 'stopshare', displayMaterialName, currentScreenShareData });
        });
    });

}

export function deleteSceneObjectById(uuid) {
    deleteSceneObject({ data: uuid }).then(() => {
        forceUnselectSceneObject().then();
        objectsModule.deleteSceneObject(uuid).then(() => {
            sendDeleteSceneObjectFromNetwork(uuid);
            sendCallbacks(uuid, "remove");
        });
    });
}

export function deleteSceneObjectFromNetwork(data) {
    remoteDeleteSceneObject({ data }).then(() => {
        objectsModule.remoteDeleteSceneObject(data.uuid);
        sendCallbacks(data.uuid, "remove");
    });
}

export function updateVideoStatusToProjectorNetwork(data) {
    // console.log(data);
    const obj = getSceneObject(data.uuid);
    switch (data.status) {
        case "new":

            if (obj.typeDisplay == "video") {
                removeVideo(obj);
            }

            stopScreenSharingBeforeNextAction(obj);

            obj.metadata = data.metadata;
            obj.typeDisplay = "video";
            if (obj.metadata) {
                switch (obj.typeDisplay) {
                    case "video":
                        loadVideo(obj);
                        break;
                }
            }

            break;
        case 'play':
            obj.metadata.isPlaying = true;
            obj.typeDisplay = "video";
            playVideo(obj);
            break;
        case 'pause':
            obj.metadata.isPlaying = false;
            obj.typeDisplay = "video";
            pauseVideo(obj);
            break;
        case 'stop':
            removeVideo(obj);
            obj.metadata = undefined;
            obj.typeDisplay = "none";
            resetProjector({ data: { uuid: data.uuid, displayMaterialName: data.displayMaterialName } }).then();
            break;

        case "mute":
            obj.metadata.muted = true;
            mutedVideo(obj);
            break;

        case "unmute":
            obj.metadata.muted = false;
            mutedVideo(obj);
            break;

        case "disableLoop":
            obj.metadata.loop = false;
            loopVideo(obj);
            break;

        case "enableLoop":
            obj.metadata.loop = true;
            loopVideo(obj);
            break;

        case "share":
            stopScreenSharingBeforeNextAction(obj);
            obj.metadata = { isPlaying: true };
            var videoElement = document.getElementById(options.roomOptions.screenShareElementID);
            currentScreenShareData = data.currentScreenShareData;
            sendVideoTextureToProjector(videoElement, data.displayMaterialName, data.uuid, obj.assetId);
            break;
        case "stopshare":
            currentScreenShareData = data.currentScreenShareData;
            resetProjector({ data: { uuid: obj.uuid, displayMaterialName: data.displayMaterialName } }).then(() => { });
            break;
        default:
            break;
    }

}


const uniques = {};
const sceneObjectsTemp = {};
const displays = {};
export function loadSceneObjectsFromDB() {
    return new Promise((resolve, reject) => {
        objectsModule.getSceneObjectsFromDB().then((sceneObjectsInDB) => {

            const loadingData = [];
            for (const key in sceneObjectsInDB) {
                if (Object.hasOwnProperty.call(sceneObjectsInDB, key)) {
                    const sceneObject = structuredClone(sceneObjectsInDB[key]);

                    loadingData.push({ uuid: sceneObject.uuid, position: sceneObject.data.position, rotation: sceneObject.data.rotation });
                    if (!uniques[sceneObject.assetId] && !sceneObject.data.isDisplay) {
                        uniques[sceneObject.assetId] = { groups: {}, assetId: sceneObject.assetId, seed: sceneObject.seed }
                        sceneObjectsTemp[sceneObject.assetId] = sceneObject;
                    }

                    if (!sceneObject.data.isDisplay)
                        addObjectPosition(sceneObject.assetId, uniques[sceneObject.assetId].groups, { position: sceneObject.data.position, name: sceneObject.data.name, rotation: sceneObject.data.rotation, scale: sceneObject.data.scale, uuid: sceneObject.uuid }, sceneObject.seed);
                    else {
                        displays[sceneObject.uuid] = sceneObject;
                    }

                    if (sceneObject.metadata) {
                        switch (sceneObject.typeDisplay) {
                            case "video":
                                loadVideo(sceneObject);
                                break;
                        }
                    }
                }
            }

            placeLoadingObject({ data: loadingData }).then(() => {
                var data = {
                    code: "finishLoadingObjectsInTheScene",
                    data: {}
                }
                options.callbacks.defaultResponse(data);
                // sendCallbacks(sceneObjectsInDB, "all");
            });


            for (const key in uniques) {
                if (Object.prototype.hasOwnProperty.call(uniques, key)) {
                    const unique = uniques[key];
                    const sceneObjectTemp = sceneObjectsTemp[key];

                    objectsModule.requestSceneObject(sceneObjectTemp.assetId, sceneObjectTemp.owner, sceneObjectTemp.ownerId).then(galleryObject => {

                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {

                            unique.buffers = buffers;
                            const d = {
                                data: unique,
                                buffers: buffers
                            }

                            createInstancedMeshLODSceneObjectFromDB(d).then(() => {

                                for (const key in unique.groups) {
                                    var g = unique.groups[key];
                                    g.positions.forEach(sceneObject => {
                                        var sceneObjectInfo = {
                                            assetId: unique.assetId,
                                            data: {
                                                position: sceneObject.position,
                                                rotation: sceneObject.rotation,
                                                scale: sceneObject.scale,

                                            },
                                            uuid: sceneObject.uuid
                                        }
                                        if (sceneObject.name) {
                                            sceneObjectInfo.data.name = sceneObject.name;
                                        }

                                        sendCallbacks(sceneObjectInfo, "add");
                                    });
                                }
                            }).catch(e => {
                                // TODO: falta colocar a función los 3 casos donde se coloca un not found
                                for (const key in unique.groups) {
                                    var g = unique.groups[key];
                                    g.positions.forEach(sceneObject => {
                                        var sceneObjectInfo = {
                                            assetId: unique.assetId,
                                            data: {
                                                position: sceneObject.position,
                                                rotation: sceneObject.rotation,
                                                scale: sceneObject.scale,

                                            },
                                            uuid: sceneObject.uuid,
                                            status: "removed"
                                        }
                                        if (sceneObject.name) {
                                            sceneObjectInfo.data.name = sceneObject.name;
                                        }
                                        sendCallbacks(sceneObjectInfo, "add");
                                    });
                                }

                                delete unique.buffers;
                                const notFound = {
                                    data: unique
                                }
                                console.log('first CreateNotFoundObject');
                                createNotFoundObject(notFound).then(() => {

                                })
                            });

                        }).catch(e => {
                            //Archivo corrupto                          

                            for (const key in unique.groups) {
                                var g = unique.groups[key];
                                g.positions.forEach(sceneObject => {
                                    var sceneObjectInfo = {
                                        assetId: unique.assetId,
                                        data: {
                                            position: sceneObject.position,
                                            rotation: sceneObject.rotation,
                                            scale: sceneObject.scale,

                                        },
                                        uuid: sceneObject.uuid,
                                        status: "removed"
                                    }
                                    if (sceneObject.name) {
                                        sceneObjectInfo.data.name = sceneObject.name;
                                    }
                                    sendCallbacks(sceneObjectInfo, "add");
                                });
                            }

                            delete unique.buffers;
                            const notFound = {
                                data: unique
                            }
                            console.log('second CreateNotFoundObject');
                            createNotFoundObject(notFound).then(() => {

                            })
                        })

                    }).catch(e => {

                        //Asset no existe en galería objects

                        for (const key in unique.groups) {
                            var g = unique.groups[key];
                            g.positions.forEach(sceneObject => {
                                var sceneObjectInfo = {
                                    assetId: unique.assetId,
                                    data: {
                                        position: sceneObject.position,
                                        rotation: sceneObject.rotation,
                                        scale: sceneObject.scale,

                                    },
                                    uuid: sceneObject.uuid,
                                    status: "removed"
                                }
                                if (sceneObject.name) {
                                    sceneObjectInfo.data.name = sceneObject.name;
                                }
                                sendCallbacks(sceneObjectInfo, "add");
                            });
                        }
                        delete unique.buffers;
                        const notFound = {
                            data: unique
                        }
                        console.log('third CreateNotFoundObject', unique);
                        createNotFoundObject(notFound).then(() => {

                        })
                    });



                }
            }

            for (const key in displays) {
                if (Object.prototype.hasOwnProperty.call(displays, key)) {
                    const display = displays[key];
                    objectsModule.requestSceneObject(display.assetId, display.owner, display.ownerId).then(galleryObject => {
                        loadGalleryLODObject({ uris: galleryObject.data.uris }).then((buffers) => {
                            const displayMaterialName = galleryObject.data.displayMaterialName
                            const d = {
                                data: {
                                    uuid: display.uuid,
                                    objectPosition: display.data,
                                    assetId: display.assetId,
                                    buffers,
                                    displayMaterialName
                                }
                            }

                            createSceneObjectByBuffers(d).then();

                        }).catch(e => {
                            console.log(e);
                        });
                    }).catch(e => {
                        console.log(e);

                    });

                }
            }



            resolve();
        }).catch(reject);



    });

}

function addObjectPosition(uuidObject, uniqueObjectGroups, objectPosition, seed = null) {
    let theGroup = null;

    for (const key in uniqueObjectGroups) {
        if (Object.prototype.hasOwnProperty.call(uniqueObjectGroups, key)) {
            const group = uniqueObjectGroups[key];
            const distance = getDistanceBetweenPoints(objectPosition.position, group.centroid);
            if (distance <= nearFactor) {
                theGroup = group;
                break;
            }
        }
    }

    if (theGroup) {
        theGroup.positions.push(objectPosition);
        theGroup.centroid = calculateCentroid(theGroup);
    } else {
        const uuid = uuidv4();
        const newGroup = {
            assetId: uuidObject,
            uuid: seed ? seed : uuid,
            positions: [objectPosition],
            centroid: objectPosition.position
        };

        if (seed)
            uniqueObjectGroups[seed] = newGroup;
        else
            uniqueObjectGroups[uuid] = newGroup;
    }

}

async function loadVideo(sceneObject) {

    var projectorData = await objectsModule.getObjectById(sceneObject.assetId, sceneObject.owner, sceneObject.ownerId);
    var videoData = await objectsModule.getObjectById(sceneObject.metadata.assetId, sceneObject.metadata.owner, sceneObject.metadata.ownerId);

    if (projectorData && videoData) {

        var displayMaterialName = projectorData.data.displayMaterialName;
        var uri = videoData.data.uris[0];
        var uuid = sceneObject.uuid;

        var container = document.getElementById(options.roomOptions.videoContainer);
        const video = document.createElement("video");
        video.src = uri;
        video.id = uuid;
        video.autoplay = true;
        video.muted = true;
        video.loop = sceneObject.metadata.loop;
        video.setAttribute('crossorigin', 'anonymous');
        container.appendChild(video);
        sceneObject.metadata.muted = true;
        if (sceneObject.metadata.isPlaying)
            video.play();
        else
            video.pause();


        sendVideoTextureToProjector(video, displayMaterialName, uuid, sceneObject.assetId);
    }
}

function playVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.play();
    }
}

function loopVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.loop = sceneObject.metadata.loop;
    }
}

function mutedVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.muted = sceneObject.metadata.muted;
    }
}

function pauseVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.pause();
    }
}

function removeVideo(sceneObject) {
    var videoElement = document.getElementById(sceneObject.uuid);
    if (videoElement) {
        videoElement.parentNode.removeChild(videoElement);
    }
}



const activeStreams = new Map();

export function sendVideoTextureToProjector(videoElement, displayMaterialName, uuid, assetId) {
    // Detener transmisión anterior si existe
    stopAllVideoTextures();

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { willReadFrequently: true });
    if (!ctx) {
        console.error('Failed to get canvas context');
        return;
    }

    const captureFrame = async () => {
        if (videoElement.readyState >= videoElement.HAVE_CURRENT_DATA) {
            canvas.width = videoElement.videoWidth;
            canvas.height = videoElement.videoHeight;
            ctx.drawImage(videoElement, 0, 0);
            const imageBitmap = await createImageBitmap(canvas);

            const data = {
                uuid,
                displayMaterialName,
                bitmap: imageBitmap,
                assetId
            };
            await setVideoTextureToprojector({ data });
        }

        if (videoElement.requestVideoFrameCallback && activeStreams.has(uuid)) {
            const callbackId = videoElement.requestVideoFrameCallback(captureFrame);
            activeStreams.get(uuid).callbackId = callbackId;
        }
    };

    activeStreams.set(uuid, {
        videoElement,
        canvas,
        callbackId: null
    });

    captureFrame();
}

export function stopVideoTexture(uuid) {
    if (activeStreams.has(uuid)) {
        const stream = activeStreams.get(uuid);
        if (stream.videoElement.requestVideoFrameCallback && stream.callbackId) {
            stream.videoElement.cancelVideoFrameCallback(stream.callbackId);
        }
        activeStreams.delete(uuid);
    }
}

export function stopAllVideoTextures() {
    for (const uuid of activeStreams.keys()) {
        stopVideoTexture(uuid);
    }
    activeStreams.clear();
}

function getDistanceBetweenPoints(point1, point2) {
    return Math.sqrt(
        Math.pow(point1[0] - point2[0], 2) +
        Math.pow(point1[1] - point2[1], 2) +
        Math.pow(point1[2] - point2[2], 2)
    );
}

function calculateCentroid(group) {
    const n = group.positions.length;
    const sumX = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[0], 0);
    const sumY = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[1], 0);
    const sumZ = group.positions.reduce((acc, objectPosition) => acc + objectPosition.position[2], 0);
    return [sumX / n, sumY / n, sumZ / n];
}

export function getAssetById(assetId) {
    return new Promise((resolve, reject) => {
        objectsModule.getObjectById(assetId).then((asset) => {
            const assetClone = JSON.parse(JSON.stringify(asset));
            resolve(assetClone);
        }).catch(reject);
    });
}


function sendCallbacks(response, type) {
    switch (type) {
        // case "all":
        //     var data = {
        //         code: "SceneObjectsFromDB",
        //         data: response,
        //     };
        //     options.callbacks.defaultResponse(data);
        //     break;
        case "remove":
            var data = {
                code: "RemoveSceneObjectFromDB",
                data: {
                    uuid: response,
                },
            };
            options.callbacks.defaultResponse(data);
            break;
        case "add":
            var data = {
                code: "AddSceneObjectToDB",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
        case "info":
            var data = {
                code: "onInfoSelectedObject",
                data: response,
            };
            options.callbacks.defaultResponse(data);
            break;
    }
}
